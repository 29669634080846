<template>
    <div :class="['flex flex-col justify-center items-center px-4 py-6 gap-y-6 relative', disabledClass]">
        <GgmsBasicEmailParts
            v-model:recipients="recipients"
            v-model:selected-sender="emailSender"
            v-model:cc="cc"
            v-model:bcc="bcc"
            type="email"
            :isReply="isReply"
            :isForward="isForward"
            :isWorkflow="isWorkflow"
            :to="to"
            :from="from"
        />
        <div v-if="!isReply" class="flex items-center w-full pb-3 border-b gap-x-2">
            <p class="text-sm font-medium text-gray-900">Subject:</p>
            <div class="flex flex-col w-full">
                <input
                    v-model="subject"
                    class="w-full h-8 px-2 py-1 text-gray-900 rounded-md focus:outline-none border-0 focus:ring-2 focus:ring-primary-color-500"
                    :class="invalidSubjectStyle"
                    @focus="clearValidationError"
                />
                <p v-if="localErrorMessage" class="mt-2 text-sm text-red-600">{{ localErrorMessage }}</p>
            </div>
        </div>
        <div class="w-full">
            <GgmsRichTextEditor
                v-model="message"
                :error-message="hasErrorMessage('body', emailStore.validationError)"
                placeholder="Write your email"
                @disable-validation-error="clearValidationError"
            />
        </div>
        <div class="flex flex-col gap-y-2 w-full">
            <DNDWarningComponent
                :recipients="recipients"
                :showContactName="true"
                :showCheckbox="true"
            ></DNDWarningComponent>
            <MarketingPreferencesWarningComponent
                :recipients="recipients"
                :showContactName="true"
                :showCheckbox="true"
                type="email"
            ></MarketingPreferencesWarningComponent>
        </div>
        <div v-if="fileList.length" class="flex flex-col flex-wrap self-start gap-y-2">
            <p class="text-sm font-medium text-gray-700">Attachments</p>
            <div>
                <GgmsChip
                    class="mb-1 mr-2 w-fit"
                    v-for="file in fileList"
                    :key="file.name"
                    @onRemove="onItemDeleted(file.name)"
                >
                    {{ file.name }}
                </GgmsChip>
            </div>
        </div>
        <div class="flex flex-row-reverse items-center justify-between w-full">
            <div class="flex items-center gap-x-4">
                <DocumentTextIcon
                    class="w-6 h-6 cursor-pointer text-gray-400 hover:text-gray-600"
                    @click="toggleTemplateModal(true)"
                />
                <GgmsFileUpload
                    v-model="fileList"
                    :multiple-files="true"
                    :accept="['image', 'video', 'audio', 'doc', 'pdf', 'excel', 'text']"
                    :max-size="5"
                    :is-button-upload="true"
                    class="flex items-center justify-center w-fit"
                >
                    <template #alternative="{ upload }">
                        <PaperClipIcon
                            class="w-6 h-6 cursor-pointer text-gray-400 hover:text-gray-600"
                            @click.stop="upload()"
                        />
                    </template>
                </GgmsFileUpload>
                <GgmsButton
                    v-if="isWorkflow"
                    :classes="['rounded-md']"
                    :disabled="isSaveButtonDisabled"
                    @click="createEmail"
                    >Save</GgmsButton
                >
                <div v-else class="flex">
                    <GgmsButton
                        :classes="['rounded-md rounded-r-none']"
                        @click="createEmail"
                        :disabled="isButtonDisabled"
                        >Send Email</GgmsButton
                    >
                    <GgmsButton
                        :classes="['px-2 rounded-md rounded-l-none']"
                        :disabled="isButtonDisabled"
                        @click="openScheduleEmailModal"
                    >
                        <CalendarIcon class="w-6 h-6 text-white" />
                    </GgmsButton>
                </div>
            </div>
            <TrashIcon class="w-6 h-6 cursor-pointer text-gray-400 hover:text-gray-600" @click="onDelete" />
        </div>
        <MessageTemplatesModal
            :open="isTemplatesModalOpen"
            template-title="Email"
            @close-modal="toggleTemplateModal(false)"
            @update:model-value="updateTemplate"
            size="3xl"
        />
    </div>

    <GgmsModal :open="isOpenScheduleEmailModal" @closeModal="closeScheduleEmailModal">
        <template #header>
            <div class="flex justify-start items-center">
                <div class="flex items-center justify-center mr-4 h-12 w-12 rounded-full bg-primary-color-100">
                    <CalendarIcon class="h-6 w-6 text-primary-color-600" />
                </div>
                <h1 class="text-lg leading-6 font-medium text-gray-900">Schedule Email</h1>
            </div>
        </template>
        <div v-for="schedule in scheduleOptions" :key="schedule.text">
            <div class="flex justify-between items-center cursor-pointer" @click="saveScheduleEmail(schedule.date)">
                <div class="flex items-center justify-center">
                    <ClockIcon class="h-5 w-5 text-primary-color-600 mr-3" />
                    <p class="text-base font-medium text-primary-color-600">{{ schedule.text }}</p>
                </div>
                <p class="text-sm text-gray-500">
                    {{ formatHumanReadableDate(schedule.date, true) }}
                </p>
            </div>
            <GgmsSeparator class="my-4" />
        </div>
        <div>
            <button class="flex items-center w-fit border-none bg-transparent" @click="toggleCustomSchedule">
                <CalendarIcon class="h-5 w-5 text-primary-color-600 mr-3" />
                <p class="text-base font-medium text-primary-color-600">Pick Date & Time</p>
            </button>
            <div v-if="isOpenCustomSchedule" class="flex gap-x-4 mt-2">
                <GgmsInput
                    v-model="customScheduleDate"
                    class="w-full"
                    type="date"
                    :iconStart="CalendarIcon"
                ></GgmsInput>
                <GgmsInput v-model="customScheduleTime" class="w-full" type="time" :iconStart="ClockIcon"></GgmsInput>
            </div>
        </div>
        <template v-if="isOpenCustomSchedule" #under>
            <div class="flex justify-end">
                <GgmsButton styleType="secondary" class="mr-3" @click="closeScheduleEmailModal">Cancel</GgmsButton>
                <GgmsButton @click="saveScheduleEmail()">Done</GgmsButton>
            </div>
        </template>
    </GgmsModal>
</template>

<script setup lang="ts">
import { onMounted, ref, computed, provide, watch } from "vue"
import { useRoute } from "vue-router"

import GgmsButton from "@/components/GgmsButton.vue"
import GgmsModal from "@/components/GgmsModal.vue"
import GgmsSeparator from "@/components/GgmsSeparator.vue"
import GgmsInput from "@/components/GgmsInput.vue"
import GgmsRichTextEditor from "@/components/GgmsRichTextEditor.vue"
import GgmsFileUpload from "@/components/GgmsFileUpload.vue"
import GgmsChip from "@/components/GgmsChip.vue"
import MessageTemplatesModal from "./message/MessageTemplatesModal.vue"
import GgmsBasicEmailParts from "@/components/GgmsBasicEmailParts.vue"
import { CalendarIcon, DocumentTextIcon, TrashIcon, ClockIcon, PaperClipIcon } from "@heroicons/vue/outline"
import { useContactsStore } from "@/stores/contacts"
import { useEmailStore } from "@/stores/email"
import { useConfigStore } from "@/stores/config"
import { useAgencyStore } from "@/stores/agency"
import { useAuthStore } from "@/stores/auth"
import { useInboxStore } from "@/stores/inbox"

import { ToastService } from "@/shared/services/toast"
import { GgmsError } from "@/shared/services/client"

import { Contact } from "@/shared/models/contact"
import { EmailPayload } from "@/shared/models/email"
import { Agent } from "@/shared/models/agent"
import { UploadFile, UploadItem } from "@/shared/models/upload-file"
import { Integration, PayloadIntegration } from "@/shared/models/integration"
import { Inbox, InboxContact } from "@/shared/models/inbox"
import { Sender } from "@/shared/models/sender"

import {
    hasErrorMessage,
    formatHumanReadableDate,
    getDateInputFormat,
    getTimeInputFormat,
    setTomoroowMorning,
    setThisAfternoon,
    formatTaskDate,
    getFullName,
    stripHtml,
} from "@/shared/utils/helpers"
import { useWorkflowStore } from "@/stores/workflow"
import { useIntegrationStore } from "@/stores/integration"
import DNDWarningComponent from "@/components/profile-details/do-not-disturb/DNDWarningComponent.vue"
import MarketingPreferencesWarningComponent from "@/components/profile-details/marketing-preferences/MarketingPreferencesWarningComponent.vue"

interface Props {
    replyToId?: string
    message?: Inbox
    integration?: PayloadIntegration
    isForward?: boolean
    isWorkflow?: boolean
    fromInbox?: boolean
    isReplyAll?: boolean
    hasContact?: boolean
    hasSelectedContacts?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    isWorkflow: false,
    fromInbox: false,
    isReplyAll: false,
    hasContact: false,
    hasSelectedContacts: false,
})

const contactStore = useContactsStore()
const emailStore = useEmailStore()
const agencyStore = useAgencyStore()
const configStore = useConfigStore()
const authStore = useAuthStore()
const inboxStore = useInboxStore()
const workflowStore = useWorkflowStore()
const integrationStore = useIntegrationStore()
const toastService = new ToastService()
const route = useRoute()

const emit = defineEmits(["onDelete", "update:replyToId", "sendFromInbox", "saveEmail"])

provide("contactOverlayTarget-to", "creatingEmail")
provide("contactOverlayTarget-cc", "emailCcField")
provide("contactOverlayTarget-bcc", "emailBccField")

const isTemplatesModalOpen = ref(false)
const subject = ref("")
const message = ref("")
const recipients = ref([] as InboxContact[])
const fromUser = ref(authStore.currentUser)
const emailSender = ref<Sender>({ name: fromUser.value?.fullName || "", email: fromUser.value?.email })
const integrations = computed(() => configStore.integrations as Integration[])

const myEmails = ref<string[]>([])
const showContactSelect = ref(false)
const isOpenScheduleEmailModal = ref(false)
const isOpenCustomSchedule = ref(false)
const isSaveButtonDisabled = computed(
    () =>
        isCreatingEmail.value ||
        (props.isWorkflow && (stripHtml(message.value).length === 0 || subject.value.length === 0))
)

const customScheduleDate = ref(getDateInputFormat(new Date()))
const customScheduleTime = ref(getTimeInputFormat(new Date()))

const scheduleDate = ref<Date>()
const scheduleOptions = ref([
    {
        text: "Tomorrow morning",
        date: setTomoroowMorning(),
    },
])
const fileList = ref<File[]>([])
const localErrorMessage = computed(() =>
    emailStore.validationError?.fullMessage?.includes("Subject") ? emailStore.validationError.message : ""
)
const invalidSubjectStyle = computed(() => (localErrorMessage.value ? "ring-1 ring-red-500 rounded-md" : ""))
const isCreatingEmail = ref(false)
const gmailIntegration = computed(() =>
    configStore.integrations.find((i) => i.integrationName === "gmail" && i.fromAddress === emailSender.value.email)
)
const sendGridIntegration = computed(() => configStore.integrations.find((i) => i.integrationName === "sendgrid"))
const outlookIntegration = computed(() =>
    configStore.integrations.find((i) => i.integrationName === "outlook" && i.isEnabled)
)

const cc = ref<InboxContact[]>([])
const bcc = ref<InboxContact[]>([])

const to = computed(() => props.message?.to?.map((to) => to.email?.emailAddress) || [])
const from = computed(() => props.message?.from?.email?.emailAddress || "")
const isReply = computed(() => !!props.message?.subject && !props.isWorkflow)
const replyToId = computed({
    get: () => props.replyToId,
    set: (value) => {
        emit("update:replyToId", value)
    },
})

const disabledClass = computed(() => (isCreatingEmail.value ? "opacity-50 pointer-events-none cursor-not-allowed" : ""))
const isButtonDisabled = computed(
    () =>
        (contactStore.haveAllDND ? !contactStore.sendAnywayDND : false) ||
        (contactStore.haveAllMP ? !contactStore.sendAnywayMP : false)
)
function addRecipient(contact: Contact, index?: number) {
    const recipient = {
        _id: contact._id,
        fullName: getFullName(contact?.firstName, contact?.lastName),
        email: contact.emails?.[0]?.emailAddress,
        doNotDisturb: contact?.doNotDisturb,
        marketingPreferences: contact?.marketingPreferences,
    } as InboxContact

    if (!recipients.value.map((recipient) => recipient._id).includes(recipient._id) && recipient?.email) {
        index ? recipients.value.splice(index, 0, recipient) : recipients.value.push(recipient)
    }
    showContactSelect.value = false
}

async function createEmail() {
    try {
        isCreatingEmail.value = true
        if (props.isWorkflow) {
            emit("saveEmail")
        }

        const to = [] as string[]
        recipients.value.forEach((contact) => {
            if (contact?.email) {
                to.push(contact.email)
            }
        })

        const emailPayload: EmailPayload = {
            body: message.value,
            plain: message.value,
            subject: subject.value,
            from: {
                email: {
                    emailAddress: emailSender.value.email || "",
                },
            },
            to: to.map((email) => ({ email: { emailAddress: email } })),
            cc: cc.value?.map((contact) => contact.email),
            bcc: bcc.value?.map((contact) => contact.email),
            themeChannel: "RichEmail",
            sendAnywayDND: contactStore.sendAnywayDND || false,
            sendAnywayMP: contactStore.sendAnywayMP || false,
        }

        // remove cc and bcc if empty
        if (!emailPayload.cc?.length) {
            delete emailPayload.cc
        }
        if (!emailPayload.bcc?.length) {
            delete emailPayload.bcc
        }

        if (gmailIntegration.value && emailSender.value?.email === gmailIntegration.value?.fromAddress) {
            emailPayload.integration = {
                _id: gmailIntegration.value._id,
                integrationName: gmailIntegration.value.integrationName,
                integrationType: gmailIntegration.value.integrationType,
            }
        }
        if (sendGridIntegration.value && emailSender.value?.integrationName === "sendgrid") {
            emailPayload.integration = {
                _id: sendGridIntegration.value._id,
                integrationName: sendGridIntegration.value.integrationName,
                integrationType: sendGridIntegration.value.integrationType,
            }
        }
        if (outlookIntegration.value && emailSender.value?.email === outlookIntegration.value?.fromAddress) {
            emailPayload.integration = {
                _id: outlookIntegration.value._id,
                integrationName: outlookIntegration.value.integrationName,
                integrationType: outlookIntegration.value.integrationType,
            }
        }

        if (emailSender.value?.email === outlookIntegration.value?.fromAddress) {
            emailPayload.integration = {
                _id: outlookIntegration.value._id,
                integrationName: outlookIntegration.value.integrationName,
                integrationType: outlookIntegration.value.integrationType,
            }
        }

        if (replyToId.value && !props.isForward) {
            emailPayload.replyToId = replyToId.value
        }

        const possibleSenderEmails = configStore.integrations
            .filter(
                (integration) =>
                    integration.integrationName === "gmail" ||
                    integration.integrationName === "outlook" ||
                    integration.integrationName === "sendgrid"
            )
            .map((integration) => integration.fromAddress)

        if (replyToId.value) {
            emailPayload.subject = setSubject()
            emailPayload.integration = props.integration
            if (possibleSenderEmails.includes(props.message?.to?.[0]?.email?.emailAddress)) {
                emailPayload.to = [{ email: { emailAddress: props.message?.from?.email?.emailAddress || "" } }]
                emailPayload.from = props.message.to[0]
            } else {
                emailPayload.to = props.message.to
                emailPayload.from = props.message.from
            }

            emailPayload.to = recipients.value.reduce((unique, recipient) => {
                const newRecipient = { email: { emailAddress: recipient.email }, _id: recipient?._id }
                if (
                    !unique.some(
                        (obj) =>
                            obj.email.emailAddress === newRecipient.email.emailAddress && obj._id === newRecipient._id
                    ) &&
                    newRecipient.email.emailAddress !== emailPayload.from.email.emailAddress
                ) {
                    unique.push(newRecipient)
                }
                return unique
            }, emailPayload.to)
        }

        if (props.isForward) {
            emailPayload.subject = setSubject()
            emailPayload.integration = props.integration

            if (possibleSenderEmails.includes(props.message?.to?.[0]?.email?.emailAddress)) {
                emailPayload.from = props.message.to[0]
            } else {
                emailPayload.from = props.message.from
            }

            emailPayload.to = recipients.value.map((recipient) => ({
                email: { emailAddress: recipient.email },
                _id: recipient?._id,
            }))
        }

        if (!emailPayload?.to?.length && !props.isWorkflow) {
            toastService.addToast({
                message: "Unable to send email. There are no contacts to send the message to.",
                type: "error",
            })
            return
        }
        emit("saveEmail")

        if (scheduleDate.value) {
            emailPayload.scheduledAt = scheduleDate.value
        }
        if (fileList.value.length) {
            const uploads: UploadItem[] = fileList.value.map((file) => ({ name: file.name, mimetype: file.type }))
            const files: UploadFile[] = await emailStore.requestPostDataForFileUpload(uploads, fileList.value)
            emailPayload.files = files
        }

        const contactId = contactStore?.contact?._id
        if (replyToId.value || props.isForward) {
            const res = (await emailStore.createEmail(emailPayload)) as { threads: Inbox[] }
            if (res?.threads?.[0]?.messages?.[0]) {
                inboxStore.selectedItem?.messages?.push(res.threads[0].messages[0])
            }
        } else if (contactId) {
            if (props.isWorkflow) {
                workflowStore.messagePayload = emailPayload
            } else {
                await emailStore.createEmail(emailPayload, contactId)
                await contactStore.getOne(contactId)
            }
        } else {
            if (props.isWorkflow) {
                workflowStore.messagePayload = emailPayload
            } else {
                await emailStore.createEmail(emailPayload)
            }
        }

        if (props.fromInbox) {
            emit("sendFromInbox")
        }

        if (!emailStore.validationError.message) {
            subject.value = ""
            message.value = ""
            scheduleDate.value = undefined
            fileList.value = []
            recipients.value = []
            addRecipient(contactStore.contact)
            replyToId.value = ""
            cc.value = []
            bcc.value = []
        }
    } finally {
        isCreatingEmail.value = false
        emit("saveEmail")
    }
}

function setSubject() {
    if (props.isForward && !props.message?.subject?.startsWith("Fwd:") && !props.isWorkflow) {
        return `Fwd: ${props.message?.subject}`
    }
    if (!props.isForward && !props.message?.subject?.startsWith("Re:") && !props.isWorkflow) {
        return `Re: ${props.message?.subject}`
    }
    return props.message?.subject || ""
}

function openScheduleEmailModal() {
    isOpenScheduleEmailModal.value = true
    const afternoonSchedule = setThisAfternoon(scheduleOptions.value)
    if (afternoonSchedule) {
        scheduleOptions.value.push(afternoonSchedule)
    }
}

function closeScheduleEmailModal() {
    isOpenScheduleEmailModal.value = false
    isOpenCustomSchedule.value = false
    customScheduleDate.value = getDateInputFormat(new Date())
    customScheduleTime.value = getTimeInputFormat(new Date())
}

function toggleCustomSchedule() {
    isOpenCustomSchedule.value = !isOpenCustomSchedule.value
}

function setScheduleTime() {
    const date = new Date(customScheduleDate.value)
    const hours = Number(customScheduleTime.value.split(":")[0])
    const minutes = Number(customScheduleTime.value.split(":")[1])
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes)
}

function saveScheduleEmail(date?: Date) {
    scheduleDate.value = date || setScheduleTime()

    createEmail()
    closeScheduleEmailModal()
}

function onItemDeleted(fileName: string) {
    fileList.value = fileList.value.filter((file) => file.name !== fileName)
}

function onDelete() {
    emit("onDelete")
}

function toggleTemplateModal(isOpen: boolean) {
    isTemplatesModalOpen.value = isOpen
}

function clearValidationError() {
    emailStore.validationError = {} as GgmsError
}

function updateTemplate(template: { subject: string; body: string }) {
    const models = {
        contact: contactStore.contact,
        agency: agencyStore.agency,
        agent: {} as Agent,
    }
    subject.value = template.subject
    message.value = template.body
    // recipients.value.length > 1 ? template.body : renderTemplate(template.body, configStore.prototypes, models)
}

function createMessageQuote() {
    if (!props.message) return

    if (props.isWorkflow) {
        message.value = props.message.body
        subject.value = props.message.subject
        emailSender.value = { name: "", email: props.message.from?.email?.emailAddress }
        return
    }

    const messageBody = props.message?.body || props.message?.plain || props.message?.snippet

    message.value = `<br/><br/><p style="font-size: 0.875rem"> On ${formatTaskDate(new Date(props.message.sentAt))} ${
        props.message.from?.email?.emailAddress.replaceAll('"', "") || ""
    } < ${
        props.message.from?.email?.emailAddress || ""
    } > wrote: </p><blockquote style="margin:0 0 0 .8ex;border-left:1px #ccc solid;padding-left:1ex"> ${messageBody} </blockquote>`

    const fromEmail = props.message?.from?.email?.emailAddress || ""
    recipients.value = [{ email: fromEmail }] as InboxContact[]
    if (props.isReplyAll) {
        convertIntegrationsToSenders()
        if (myEmails.value.some((email) => props.message?.cc?.includes(email))) {
            cc.value = props.message.to?.map((recipient) => {
                return {
                    email: recipient.email?.emailAddress,
                }
            })
        } else if (
            myEmails.value.some((email) =>
                props.message?.to?.map((recipients) => recipients.email?.emailAddress).includes(email)
            )
        ) {
            cc.value = props.message.cc?.map((email) => {
                return { email }
            })
        } else {
            cc.value = props.message.to
                ?.map((recipient) => {
                    return {
                        email: recipient.email?.emailAddress,
                    }
                })
                .concat(
                    props.message.cc?.map((email) => {
                        return { email }
                    }) as { email: string }[]
                )
        }
    }
}

function getSendgridSenderProp(propName: string) {
    return integrationStore.integrationConfig?.versions
        ?.at(-1)
        ?.sectionAnswers?.[1]?.answers?.find((answer) => answer.name === propName)?.textAnswers?.[0]
}

async function convertIntegrationsToSenders() {
    integrations.value
        .filter((integration) => integration.integrationType === "inboxEmail" && integration.isEnabled)
        .forEach((integration) => {
            myEmails.value.push(integration.fromAddress as string)
        })

    const sendgridIntegration = integrations.value.find((integration) => integration.integrationName === "sendgrid")
    if (!sendgridIntegration) {
        return
    }
    await integrationStore.getIntegration(sendgridIntegration._id)

    const sendgridSenderEmail = getSendgridSenderProp("item_5")
    const sendgridSenderName = getSendgridSenderProp("item_6")

    if (!sendgridSenderName || !sendgridSenderEmail) {
        return
    }
    myEmails.value.push(sendgridSenderEmail)
}

onMounted(() => {
    if ((!props.isWorkflow && route.name !== "inbox") || props.hasContact) {
        addRecipient(contactStore.contact)
    }
    if ((!props.isWorkflow && route.name !== "inbox") || props.hasSelectedContacts) {
        if (contactStore.tableState.selectedData && contactStore.tableState.selectedData.length > 0) {
            contactStore.tableState.selectedData.forEach((contact) => {
                addRecipient(contact)
            })
        }
    }
    createMessageQuote()
    if (props.isForward) {
        recipients.value = []
    }
})

watch(
    () => contactStore.contact.emails,
    () => {
        const contactRecipientIndex = recipients.value.findIndex(
            (recipient) => recipient._id === contactStore.contact._id
        )
        if (contactRecipientIndex < 0) {
            return
        }
        addRecipient(contactStore.contact, contactRecipientIndex)
    },
    { deep: true }
)
</script>

<style scoped>
.contact {
    @apply py-1 pl-2.5 pr-2 rounded-full text-sm font-medium text-gray-800 bg-gray-100 mr-1 h-6 align-middle justify-center items-center inline-flex my-1;
}
</style>

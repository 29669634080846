import Crud from "./crud"
import { Task, TaskSession, TaskSessionBody } from "@/shared/models/task"
import { Client } from "@/shared/services/client"
import { PagedResponse } from "@/shared/models/paged-response"
import { Tag } from "@/shared/models/tag"

const client = new Client()

export class TaskService extends Crud<Task> {
    constructor() {
        super("crm/tasks")
    }

    getTasks(): Promise<PagedResponse<Task>> {
        return client.get(`${this.endpoint}?start=0&length=10`)
    }

    createTask(data: Partial<Task>): Promise<{ task: Task }> {
        return client.post(this.endpoint, data)
    }

    updateTask(id: string, data: Partial<Task>): Promise<{ task: Task }> {
        return client.put(`${this.endpoint}/${id}`, data)
    }

    bulkDelete(ids: string[], all: boolean): Promise<{ deletedCount: number }> {
        return client.post(`${this.endpoint}/bulk/delete`, { ids, all })
    }

    bulkUpdate(ids: string[], all: boolean, status: string): Promise<{ updatedCount: number }> {
        const updates = { status }
        return client.post(`${this.endpoint}/bulk/update`, { ids, all, updates })
    }

    updateTaskStatus(id: string, status: string): Promise<Task> {
        return client.put(`${this.endpoint}/${id}/status`, {
            status,
        })
    }

    createTaskSession(body: TaskSessionBody): Promise<TaskSession> {
        return client.post(`${this.endpoint}/sessions`, body)
    }

    getActiveTaskSession(): Promise<TaskSession> {
        return client.get(`${this.endpoint}/sessions/active`)
    }

    closeTaskSession(): Promise<{ session: null }> {
        return client.delete(`${this.endpoint}/sessions/active`)
    }

    bulkUpdateTagOnTask(taskIds: string[], toAdd: Tag[], toRemove: Tag[], all: boolean) {
        return client.post(`${this.endpoint}/bulk/update/tags`, {
            ids: taskIds,
            all,
            updates: {
                toAdd,
                toRemove,
            },
        })
    }

    checkTaskList(typeName: string, entityIds: string[]) {
        return client.get(`${this.endpoint}/listCheck`, { params: { typeName, entityIds } })
    }
}

import { Property } from "@/shared/models/property"
import { Client } from "@/shared/services/client"
import Crud from "@/shared/services/crud"
import { PropertyGroupLayout } from "@/shared/models/property-group-layout"

const client = new Client()

export class PropertyGroupLayoutService extends Crud<PropertyGroupLayout> {
    constructor() {
        super("crm/property-group-layout")
    }

    getCRMLayoutForAgency() {
        return client.get(`${this.endpoint}/agency`)
    }
}

import { defineStore } from "pinia"
import { CachedOrigins, Origin } from "@/shared/models/origin"
import { TableState } from "@/shared/models/table"
import { Job } from "@/shared/models/jobs"
import { ToastService } from "@/shared/services/toast"
import { OriginService } from "@/shared/services/origin"
import { useGridCollectionStore } from "@/stores/grid-collection"
import { useSocketStore } from "@/stores/socket"
import { GgmsError } from "@/shared/services/client"

export const useOriginStore = defineStore("origin", {
    state: () => ({
        tableState: {
            data: [],
            selectedData: [],
            currentPage: 0,
            lastPage: 0,
            pageLength: 50,
            numberOfPages: 0,
            total: 0,
            isAllSelected: false,
            isLoading: false,
            firstIds: [],
        } as TableState<Origin>,
        originService: new OriginService(),
        cachedOrigins: {} as CachedOrigins,
        toastService: new ToastService(),
        gridCollectionStore: useGridCollectionStore(),
        socketStore: useSocketStore(),
    }),
    actions: {
        async getOrigins() {
            try {
                this.tableState.isLoading = true

                let lastId = null
                let offset = null

                // If we're going to the next page, use keyset pagination.
                if (this.tableState.currentPage === (this.tableState?.lastPage ?? 0) + 1) {
                    lastId =
                        this.tableState.data.length > 0
                            ? this.tableState.data[this.tableState.data?.length - 1]._id
                            : null
                }
                // If we're jumping to a specific page, use offset-based pagination.
                else if (
                    this.tableState.currentPage !== (this.tableState?.lastPage ?? 0) + 1 &&
                    this.tableState.currentPage > 1
                ) {
                    offset = (this.tableState.currentPage - 1) * this.tableState.pageLength
                }

                let filtersGroups
                // use filtersGroups only if we're on origins page
                if (this.gridCollectionStore.collectionType === "origins") {
                    filtersGroups = this.gridCollectionStore?.grid?.filtersGroups?.length
                        ? encodeURIComponent(JSON.stringify(this.gridCollectionStore.grid.filtersGroups))
                        : undefined
                }

                const response = await this.originService.getAll({
                    length: this.tableState.pageLength,
                    column: this.tableState.column,
                    order: this.tableState.order,
                    search: this.tableState.search,
                    filtersGroups,
                    lastId,
                    offset,
                })

                // Store the first ID of the new page.
                if (this.tableState.firstIds && this.tableState.currentPage) {
                    this.tableState.firstIds[this.tableState.currentPage - 1] = response.data[0]?._id
                }

                this.tableState.selectedData = []
                this.tableState.data = response.data
                this.tableState.total = response.total
                this.tableState.numberOfPages = Math.ceil(this.tableState.total / this.tableState.pageLength)
            } finally {
                this.tableState.isLoading = false
            }
        },

        resetTableState() {
            this.tableState.data = []
            this.tableState.total = 0
            this.tableState.selectedData = []
            this.tableState.numberOfPages = Math.ceil(1)
        },

        async getOriginGrid() {
            try {
                this.tableState.isLoading = true
                const response = await this.gridCollectionStore.getGrid("origins")

                await this.getOrigins()
                return response
            } finally {
                this.tableState.isLoading = false
            }
        },

        async updateOriginGrid() {
            try {
                this.tableState.isLoading = true
                if (!this.gridCollectionStore.grid) return
                this.gridCollectionStore.grid.filtersGroups = this.gridCollectionStore.filtersGroups || []

                const response = await this.gridCollectionStore.updateGrid("origins", this.gridCollectionStore.grid)
                this.gridCollectionStore.gridResponse = response
                this.gridCollectionStore.grid = this.gridCollectionStore.gridResponse.grid

                await this.getOrigins()
            } finally {
                this.tableState.isLoading = false
            }
        },

        async createOrigin(origin: Origin) {
            try {
                this.tableState.isLoading = true
                this.tableState.error = {} as GgmsError
                const { job } = await this.originService.createOrigin(origin)
                this.socketStore.addJob(job)
                this.tableState.data.unshift(origin)
                this.toastService.addToast({
                    type: "success",
                    message: `${origin.displayName} created.`,
                })
                // I have to get all origins again because I get back a job, and for updating/deleting the new origin I need its _id
                await this.getOriginGrid()
            } catch (error) {
                this.tableState.error = error as GgmsError
                throw error
            } finally {
                this.tableState.isLoading = false
            }
        },

        async updateOrigin(id: string, origin: Partial<Origin>) {
            try {
                this.tableState.isLoading = true
                this.tableState.error = {} as GgmsError
                const { origin: responseOrigin } = await this.originService.updateOrigin(id, origin)
                const updatedOrigin = this.tableState.data.find((origin) => origin._id === id)
                if (updatedOrigin) {
                    this.tableState.data = this.tableState.data.map((t: Origin) => {
                        if (t._id === id) {
                            return responseOrigin
                        }
                        return t
                    })
                    this.toastService.addToast({
                        type: "success",
                        message: `${responseOrigin.displayName} updated.`,
                    })
                }
            } catch (error) {
                this.tableState.error = error as GgmsError
            } finally {
                this.tableState.isLoading = false
            }
        },

        async deleteOrigin(id: string) {
            try {
                await this.originService.deleteOrigin(id)
                const deleteOrigin = this.tableState.data.find((origin) => origin._id === id)
                if (deleteOrigin) {
                    this.tableState.data = this.tableState.data.filter((origin) => origin._id !== id)
                    this.toastService.addToast({
                        type: "success",
                        message: `${deleteOrigin.displayName} deleted.`,
                    })
                }
                this.tableState.total -= 1
            } catch (err) {
                const error = err as GgmsError
                if (error?.code === "OriginsInUse") {
                    throw error?.message
                }
            }
        },

        async bulkDeleteOrigins() {
            try {
                const ids = this.tableState.selectedData?.map((tag) => tag._id) || []
                const tagIds = this.tableState.isAllSelected ? [] : ids
                await this.originService.bulkDeleteOrigin(tagIds, !!this.tableState.isAllSelected)
                this.tableState.data = this.tableState.data.filter((tag) => !ids.includes(tag._id))
            } catch (err) {
                const error = err as GgmsError
                if (error?.code === "OriginsInUse") {
                    throw error?.message
                }
            }
        },

        // Used when opening filter sidebar from contacts page for preserving the selected filters
        async getAllOrigins() {
            const response = (await this.originService.getCachedOrigins()).data
            this.tableState.data = response
        },

        async getCachedOrigins() {
            const response = await this.originService.getCachedOrigins()
            if (response && response.data) {
                response.data.forEach((origin) => {
                    this.cachedOrigins[origin._id] = origin
                })
            } else {
                this.cachedOrigins = {}
            }
        },

        getOriginFromCache(id: string) {
            return this.cachedOrigins[id]
        },
    },
})

import { Contact } from "@/shared/models/contact"
import { PhoneCall, PhoneCallState } from "@/shared/models/phone-call"
import { PhoneCallService } from "@/shared/services/phone-call"
import { TwilioVoiceClient } from "@/shared/services/twilio"
import { ToastService } from "@/shared/services/toast"
import { GgmsError } from "@/shared/services/client"
import { defineStore } from "pinia"
import { Phone } from "@/shared/models/phone"

export const usePhoneCallStore = defineStore("phoneCall", {
    state: () => ({
        phoneCallService: new PhoneCallService(),
        phoneCallState: "idle" as PhoneCallState,
        phoneNumber: "",
        selectedContact: {} as Contact,
        currentPhoneCall: {} as Partial<PhoneCall>,
        phoneCalls: [] as PhoneCall[],
        twilioClient: {} as TwilioVoiceClient,
        twilioToken: "",
        isMicMuted: false,
        callDuration: 0,
        callDurationInterval: {} as ReturnType<typeof setTimeout>,
        toastService: new ToastService(),
        contactId: "",
        remainingPoints: -1,
    }),
    actions: {
        async setupCall(setupCallPayload: Pick<PhoneCall, "fromPhoneNumber" | "integration" | "toPhoneNumber">) {
            try {
                const { token, contactId, remainingPoints } = await this.phoneCallService.setupCall(setupCallPayload)
                this.phoneCallState = "calling"
                this.twilioToken = token
                this.contactId = contactId
                this.remainingPoints = remainingPoints
            } catch (error) {
                const err = error as GgmsError
                if (err?.fullMessage === "Integration._id must be a mongodb id") {
                    err.message = "You need to set up an integration to make a call"
                }
            }
        },

        async createCall(phoneCall: Partial<PhoneCall>) {
            const { call } = await this.phoneCallService.createCall(phoneCall)
            this.currentPhoneCall = call
        },

        async getCalls() {
            const phoneCalls = await this.phoneCallService.getCalls()
            this.phoneCalls = phoneCalls.data
        },

        async editCall(phoneCall: Partial<PhoneCall>, id?: string) {
            if (!id && !this.currentPhoneCall._id) {
                return
            }
            const { call } = await this.phoneCallService.editCall(id || this.currentPhoneCall._id || "", phoneCall)
            this.currentPhoneCall = call
        },

        async refillPointsReserve(toPhoneNumber: Phone) {
            try {
                const { remainingPoints } = await this.phoneCallService.refillPointsReserve(toPhoneNumber)
                this.remainingPoints = remainingPoints
                if (remainingPoints < 0) {
                    this.phoneCallState = "finished"
                    this.disconnectTwilio()
                    this.toastService.addToast({
                        message: "You don't have enough points to continue the call",
                        type: "error",
                    })
                }
            } catch (error) {
                console.warn("Twilio client logs error: ", error)
            }
        },

        async connectTwilio() {
            this.twilioClient = new TwilioVoiceClient()

            this.callDuration = 0

            this.twilioClient.addEventListener("statusChange", (status: PhoneCallState) => {
                if (status === "finished") {
                    clearInterval(this.callDurationInterval)
                }
                this.phoneCallState = status
            })
            this.twilioClient.addEventListener("createCall", (id: string) => {
                this.createCall({ ...this.currentPhoneCall, externalId: id })
            })

            // Add event listener for mute change to keep UI in sync
            this.twilioClient.addEventListener("muteChange", (isMuted: boolean) => {
                this.isMicMuted = isMuted
            })

            await this.twilioClient.connect(this.twilioToken, this.phoneNumber)

            this.callDurationInterval = setInterval(() => {
                this.callDuration += 1
            }, 1000)
        },

        disconnectTwilio() {
            this.twilioClient.disconnect()
            clearInterval(this.callDurationInterval)
        },

        toggleMute() {
            this.isMicMuted = !this.isMicMuted
            this.twilioClient.toggleMute()
        },

        async lookupPhoneNumber(phoneNumber: string) {
            return this.phoneCallService.lookupPhoneNumber(phoneNumber)
        },
    },
})

<template>
    <GgmsModal :open="props.open" @closeModal="closeModal">
        <template #header>
            <div class="flex justify-flex-start items-center">
                <div class="mr-4 flex items-center justify-center h-12 w-12 rounded-full bg-primary-color-100">
                    <UserAddIcon class="h-6 w-6 text-primary-color-600" aria-hidden="true" />
                </div>
                <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">Add Contact</DialogTitle>
            </div>
        </template>
        <form class="flex flex-col gap-y-[30px]" @submit.prevent="addContact">
            <div class="grid grid-cols-2 gap-4">
                <GgmsInput
                    v-model="firstName"
                    name="first-name"
                    label="First name"
                    :invalid="isInvalid('firstname', error)"
                    :errorMessage="hasErrorMessage('firstname', error)"
                />
                <GgmsInput
                    v-model="lastName"
                    name="last-name"
                    label="Last name"
                    :invalid="isInvalid('lastname', error)"
                    :errorMessage="hasErrorMessage('lastname', error)"
                />
            </div>
            <div class="grid grid-cols-2 gap-4">
                <GgmsInput
                    v-model="email"
                    type="email"
                    name="email"
                    label="Email address"
                    :invalid="isInvalid(errorField, error)"
                    :errorMessage="hasErrorMessage(errorField, error)"
                />
                <GgmsPhoneTelInput
                    v-model="phone"
                    name="phone"
                    label="Cell phone"
                    placeholder="Enter phone number"
                    :readonly="isPhoneInputReadOnly"
                    :invalid="isInvalid('phone.number', error)"
                    :errorMessage="hasErrorMessage('phone.number', error)"
                />
            </div>
            <GgmsDropdown
                v-model="selectedDomain"
                :options="domainOptions"
                name="domain"
                label="Domain"
                :is-show-clear="true"
                optionLabel="url"
                optionValue="_id"
                placeholder="Select Domain"
                class="w-full"
                :invalid="isInvalid('domain', error)"
                :error-message="hasErrorMessage('domain', error)"
            />
            <GgmsMultiSelect
                v-model="origin"
                label="Origins"
                placeholder="Select a contact origin"
                display="chip"
                :options="origins"
                optionLabel="displayName"
                classes="w-full"
            />
            <GgmsMultiSelect
                v-model="tag"
                label="Tags"
                placeholder="+ Add Tag"
                display="chip"
                :options="tags"
                optionLabel="displayName"
                :invalid="isInvalid('tags', error)"
                :errorMessage="hasErrorMessage('tags', error)"
                classes="w-full"
            />
        </form>
        <template #under>
            <div class="flex justify-end">
                <GgmsButton styleType="secondary" @click="closeModal">Cancel</GgmsButton>
                <GgmsButton class="ml-3" :disabled="isLoading" @click="addContact">Add contact</GgmsButton>
            </div>
        </template>
    </GgmsModal>
</template>

<script lang="ts" setup>
import { computed, ref, watch, nextTick } from "vue"
import { useRoute } from "vue-router"
import { DialogTitle } from "@headlessui/vue"
import GgmsButton from "@/components/GgmsButton.vue"
import GgmsModal from "@/components/GgmsModal.vue"
import GgmsInput from "@/components/GgmsInput.vue"
import GgmsPhoneTelInput from "@/components/GgmsPhoneTelInput.vue"
import GgmsDropdown from "@/components/GgmsDropdown.vue"
import GgmsMultiSelect from "@/components/GgmsMultiSelect.vue"
import { UserAddIcon } from "@heroicons/vue/outline"
import { useContactsStore } from "@/stores/contacts"
import { useGridCollectionStore } from "@/stores/grid-collection"
import { useTagStore } from "@/stores/tag"
import { useAgencyStore } from "@/stores/agency"
import { useOriginStore } from "@/stores/origin"
import { ToastService } from "@/shared/services/toast"
import { Phone } from "@/shared/models/phone"
import { isInvalid, hasErrorMessage, getDomainId } from "@/shared/utils/helpers"
import { isValidPhoneNumber } from "libphonenumber-js"
import { Contact, CreateContactResponse } from "@/shared/models/contact"
import { GgmsError } from "@/shared/services/client"
import { Tag } from "@/shared/models/tag"
import { usePhoneCallStore } from "@/stores/phone-call"
import { PHONE_STATUS } from "@/shared/models/phone-number"

const phoneCallStore = usePhoneCallStore()
const originStore = useOriginStore()
const contactsStore = useContactsStore()
const gridCollectionStore = useGridCollectionStore()
const tagStore = useTagStore()
const agencyStore = useAgencyStore()
const toastService = new ToastService()
const route = useRoute()

interface Props {
    open: boolean
    contact?: Partial<Contact>
}

const props = defineProps<Props>()

const emit = defineEmits(["closeModal"])

const collections = computed(() => gridCollectionStore.collectionsResponse)

const addContactModalOpen = ref(false)
const firstName = ref()
const lastName = ref()
const email = ref()
const phone = ref({ country: "RO", number: "" } as Phone)
const origin = ref([])
const tag = ref([])

const error = ref()
const errorField = ref("")
const isLoading = ref(false)
const origins = computed(() => originStore.tableState.data || [])
const tags = ref<Tag[]>([])

const isPhoneInputReadOnly = computed(() => !!props.contact?.phone?.number)

const selectedDomain = ref(initializeDomain())
const domainOptions = computed(() => agencyStore.agency.agencyDomains)

async function addContact() {
    try {
        isLoading.value = true
        error.value = undefined

        if (phone.value?.number && !isValidPhoneNumber(phone.value?.number, phone.value.country)) {
            error.value = {
                field: "phone.number",
                message: "Invalid phone number",
            }
            isLoading.value = false
            return
        }

        if (!selectedDomain.value) {
            nextTick(() => {
                error.value = {
                    field: "domain",
                    message: "Domain is required. Please select a domain.",
                }
            })
            isLoading.value = false
            return
        }
        localStorage.setItem("contactDomainId", selectedDomain.value)

        //lookup for phone number
        if (phone.value.number) {
            const lookupResponse = await phoneCallStore.lookupPhoneNumber(phone.value.number)
            if (lookupResponse) {
                phone.value.lineType = lookupResponse.type
                phone.value.status =
                    lookupResponse.carrier_name && !lookupResponse.error ? PHONE_STATUS.ACTIVE : PHONE_STATUS.UNKNOWN
            }
        }

        const payload = {
            firstName: firstName.value?.trim() || undefined,
            lastName: lastName.value?.trim() || undefined,
            email: email.value ? { emailAddress: email.value?.trim() } : undefined,
            phone: phone.value?.number
                ? ({
                      country: phone.value.country,
                      number: phone.value.number,
                      lineType: phone.value.lineType,
                      status: phone.value.status,
                  } as Phone)
                : undefined,
            origins: origin.value?.length ? origin.value : undefined,
            tags: tag.value?.length ? tag.value : undefined,
        }

        const response = props.contact?._id
            ? await contactsStore.updateContact(props.contact._id, payload)
            : await contactsStore.addContact(payload)

        localStorage.removeItem("contactDomainId")

        if (!response) {
            return
        }

        toastService.addToast({
            type: "success",
            message: (response as CreateContactResponse)?.updatedExisting
                ? "There was already a contact that was updated with the new details."
                : "A new contact was created.",
        })

        addContactModalOpen.value = false
        isLoading.value = false

        gridCollectionStore.collectionType = "contact"
        await gridCollectionStore.getCollections()

        if (route.name === "contacts") {
            if (gridCollectionStore.selectedTabId) {
                contactsStore.tableState.ids = collections.value
                    .find(
                        (collectionResponse) => collectionResponse.collection._id === gridCollectionStore.selectedTabId
                    )
                    ?.ids.join(",")
            }

            await contactsStore.loadContacts(!!contactsStore.tableState.filtersGroups?.length, true)
        }

        closeModal()
    } catch (e) {
        nextTick(() => {
            const err = e as GgmsError
            error.value = err
            if (["email", "email.emailaddress"].includes(err.field as string)) {
                errorField.value = err.field as string
            }
        })
        localStorage.removeItem("contactDomainId")
    } finally {
        isLoading.value = false
    }
}

function closeModal() {
    emit("closeModal")
    firstName.value = undefined
    lastName.value = undefined
    email.value = undefined
    phone.value = { country: "RO", number: "" }
    origin.value = []
    tag.value = []
    error.value = undefined
    selectedDomain.value = initializeDomain()
}

function initializeDomain() {
    const domain = getDomainId()
    return domain === "all" ? null : domain
}

watch(
    () => agencyStore.selectedDomain,
    (newValue) => {
        selectedDomain.value = newValue._id === "all" ? "" : newValue._id
    }
)

watch(
    () => props.open,
    async (value) => {
        if (value) {
            tags.value = await tagStore.getTagsForContacts()
        }

        if (!value || !props.contact) {
            return
        }
        phone.value.number = props.contact?.phone?.number || ""
        phone.value.country = props.contact?.phone?.country || "RO"
    }
)
</script>

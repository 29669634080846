import { FormAnswer } from "@/shared/models/form"
import { BaseIntegration, IntegrationConfig, PayloadIntegration } from "@/shared/models/integration"
import { IntegrationService } from "@/shared/services/integration"
import { defineStore } from "pinia"
import { useFormStore } from "@/stores/form"
import { ToastService } from "@/shared/services/toast"

export const useIntegrationStore = defineStore("Integration", {
    state: () => ({
        integrationService: new IntegrationService(),
        integrations: [] as BaseIntegration[],
        enabledIntegration: {} as BaseIntegration,
        integrationConfig: {} as IntegrationConfig,
        formStore: useFormStore(),
        toastService: new ToastService(),
    }),
    actions: {
        async getIntegrations() {
            const res = await this.integrationService.getIntegrations()
            if (res) {
                this.integrations = res.data
            }
        },

        async getIntegration(integrationId?: string) {
            const res = await this.integrationService.getIntegration(integrationId)
            this.integrationConfig = res.integration.config

            if (res?.integration?.config) {
                this.formStore.currentFormAnswer = (
                    res.integration.integrationType === "contactManager"
                        ? this.integrationConfig
                        : this.integrationConfig.versions?.at(-1)
                ) as FormAnswer
            }
        },

        async canEnableIntegration(integration: Omit<PayloadIntegration, "_id">) {
            const response = await this.integrationService.canEnableIntegration(integration)
            return response
        },

        async enableIntegration(integration: Omit<PayloadIntegration, "_id">) {
            try {
                const response = await this.integrationService.enableIntegration(integration)
                if (integration.integrationType === "contactManager" && response) {
                    if (!Object.keys(response?.integration?.config)?.length) {
                        this.toastService.addToast({
                            type: "error",
                            message: "Integration not enabled",
                        })
                        return
                    }
                    this.formStore.currentFormAnswer = response.config as FormAnswer
                }

                return response
            } catch (e) {
                console.log(e)
            }
        },

        async disableIntegration(integrationId: string) {
            const { integration } = await this.integrationService.disableIntegration(integrationId)
            if (!integration) {
                return
            }
            const index = this.integrations.findIndex((i) => i._id === integrationId)
            if (index === -1) {
                return
            }
            this.integrations[index].integration = integration
        },
    },
})

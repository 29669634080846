import { defineStore } from "pinia"
import { EmailService } from "@/shared/services/email"
import { Email, EmailPayload } from "@/shared/models/email"
import { ToastService } from "@/shared/services/toast"
import { useTimelineStore } from "./timeline"
import { GgmsError } from "@/shared/services/client"
import { useContactsStore } from "./contacts"
import { UploadFile, UploadItem } from "@/shared/models/upload-file"

export const useEmailStore = defineStore("email", {
    state: () => ({
        emailService: new EmailService(),
        toastService: new ToastService(),
        isLoading: false,
        timelineStore: useTimelineStore(),
        contactsStore: useContactsStore(),
        validationError: {} as GgmsError,
    }),
    actions: {
        async createEmail(email: EmailPayload, contactId?: string) {
            try {
                this.isLoading = true
                const res = await this.emailService.createEmail(email)
                if (!res) {
                    return
                }
                const toastMessage = email?.scheduledAt
                    ? "The email has been scheduled successfully"
                    : "The email has been sent successfully"
                this.toastService.addToast({
                    message: toastMessage,
                    type: "success",
                })
                if (contactId) {
                    this.timelineStore.getTimeline(contactId)
                }
                return res
            } catch (error) {
                const err = error as GgmsError
                if (err.code === "ValidationError") {
                    this.validationError = err
                }
            } finally {
                this.isLoading = false
            }
        },

        async updateEmail(emailId: string, email: Partial<Email>) {
            try {
                this.isLoading = true
                const res = await this.emailService.updateEmail(emailId, email)
                if (res) {
                    this.toastService.addToast({
                        message: "Email was updated",
                        type: "success",
                    })
                    this.timelineStore.getTimeline(this.contactsStore.contact._id)
                }
            } catch (error) {
                const err = error as GgmsError
                if (err.code === "ValidationError") {
                    this.validationError = err
                }
            } finally {
                this.isLoading = false
            }
        },

        async cancelEmail(emailId: string, isDelete?: boolean) {
            try {
                this.isLoading = true
                const res = await this.emailService.cancelEmail(emailId)
                if (res) {
                    this.toastService.addToast({
                        message: isDelete ? "Email has been deleted" : "Sending email successfully canceled",
                        type: "success",
                    })
                    this.timelineStore.getTimeline(this.contactsStore.contact._id)
                }
            } catch (error) {
                const err = error as GgmsError
                if (err.code === "ValidationError") {
                    this.validationError = err
                }
            } finally {
                this.isLoading = false
            }
        },

        async requestPostDataForFileUpload(uploads: UploadItem[], files: File[]): Promise<UploadFile[]> {
            const response = await this.emailService.requestPostDataForFileUpload(uploads)
            let uploadedFiles = []
            if (response) {
                uploadedFiles = await Promise.all(
                    response.presignedPostUrls.map((file, index) => {
                        const formData = new FormData()
                        Object.entries(file.fields).forEach(([key, value]) => {
                            formData.append(key, value)
                        })
                        formData.append("file", files[index])
                        return this.emailService.uploadFiles(file.url, formData)
                    })
                )
            }
            uploadedFiles = uploadedFiles.map((file) => file?.data?.upload)

            return uploadedFiles
        },
    },
})

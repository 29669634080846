import { Inbox, InboxContact } from "@/shared/models/inbox"
import { TableState } from "@/shared/models/table"
import { InboxService } from "@/shared/services/inbox"
import { User } from "@/shared/models/user"
import { getContactData, getUser } from "@/shared/utils/helpers"
import { defineStore } from "pinia"
import { useContactsStore } from "./contacts"
import { useGridCollectionStore } from "./grid-collection"

export const useInboxStore = defineStore("inbox", {
    state: () => ({
        tableState: {
            data: [] as Inbox[],
            currentPage: 0,
            lastPage: 0,
            pageLength: 20,
            column: "sentAt",
            order: -1,
            type: "",
            total: 0,
            unreadCount: 0,
            isLoading: false,
            search: "",
            ids: "",
            firstIds: [],
        } as TableState<Inbox>,
        inboxService: new InboxService(),
        selectedItem: {} as Inbox,
        isInboxLoading: false,
        contactStore: useContactsStore(),
        gridCollectionStore: useGridCollectionStore(),
    }),
    actions: {
        async getInbox(isLoadMore = false) {
            try {
                this.tableState.isLoading = true

                const filters: { [key: string]: string | number | undefined } = {
                    start: this.tableState.currentPage,
                    length: this.tableState.pageLength,
                    column: this.tableState.column,
                    order: this.tableState.order,
                    ids: this.gridCollectionStore.ids || undefined,
                }
                this.tableState.search ? (filters.search = this.tableState.search) : delete filters.search

                let lastId = null
                let offset = null

                // If we're going to the next page, use keyset pagination.
                if (this.tableState.currentPage === (this.tableState?.lastPage ?? 0) + 1) {
                    lastId =
                        this.tableState.data.length > 0
                            ? this.tableState.data[this.tableState.data?.length - 1]._id
                            : null
                }
                // If we're jumping to a specific page, use offset-based pagination.
                else if (
                    this.tableState.currentPage !== (this.tableState?.lastPage ?? 0) + 1 &&
                    this.tableState.currentPage > 1
                ) {
                    offset = (this.tableState.currentPage - 1) * this.tableState.pageLength
                }

                // Todo: To be completed after we discuss filtering on inbox
                const filtersGroups = this.gridCollectionStore?.filtersGroups?.length
                    ? encodeURIComponent(JSON.stringify(this.gridCollectionStore.filtersGroups))
                    : undefined

                const res = await this.inboxService.getInbox({ ...filters, lastId, offset, filtersGroups })

                if (!res || res.data?.length === 0) {
                    this.tableState.data = []
                    this.tableState.total = 0
                    this.tableState.unreadCount = 0
                    return
                }

                if (!isLoadMore) {
                    this.tableState.data = res.data
                    this.tableState.total = res.total
                    this.tableState.unreadCount = res.unreadCount

                    return
                }
                this.tableState.data = [...this.tableState.data, ...res.data]
            } finally {
                this.tableState.isLoading = false
            }
        },

        async markAsRead(id: string) {
            await this.inboxService.markAsRead(id)
            const readInboxItemIndex = this.tableState.data.findIndex((item) => item._id === id)
            if (readInboxItemIndex !== -1) {
                this.tableState.data[readInboxItemIndex].unread = false
                this.tableState.unreadCount!--
            }
        },

        async getThread(id: string, contactId?: string) {
            const { thread } = await this.inboxService.getThread(id)
            if (!thread?._id) {
                return
            }
            const threadIndex = this.tableState.data.findIndex((item) => item._id === id)
            if (threadIndex === -1) {
                return
            }
            this.tableState.data[threadIndex] = thread
            await this.selectItem(thread, contactId)
            this.selectedItem = thread
        },

        async selectItem(item: Inbox, contactId?: string) {
            const user = getUser()
            if (!Object.keys(item).length) {
                this.selectedItem = {} as Inbox
                return
            }

            if (contactId === user._id || !contactId) {
                const contactData = getContactData(item, user)
                this.contactStore.contact = {
                    firstName: contactData.email?.emailAddress.toString(),
                    ...(contactData.email && { emails: [contactData.email] }),
                    ...(contactData.phone && { phones: [contactData.phone] }),
                    isUnknownContact: true,
                    ...contactData,
                }
                return
            }

            await this.contactStore.getOne(contactId as string)
        },

        clearFilters() {
            this.gridCollectionStore.filtersGroups = []

            this.getInbox()
        },

        async autocomplete(search?: string, length?: number): Promise<InboxContact[]> {
            return await this.inboxService.autocomplete(search, length)
        },
    },
})

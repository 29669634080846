<template>
    <div class="flex flex-col">
        <p class="text-lg contacting-7 font-medium text-black pb-6">
            Schedule when the workflow should trigger. Select a schedule type:
        </p>
        <div class="flex flex-col gap-y-3">
            <DelayTypeCard
                v-for="schedule in scheduleTypes"
                :key="schedule.title"
                :title="schedule.title"
                :example="schedule.example"
                @click="selectScheduleType(schedule.component)"
            ></DelayTypeCard>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { Component } from "vue"
import DelayTypeCard from "@/components/workflows/sidebar/action/delay/DelayTypeCard.vue"
import CalendarDateScheduleComponent from "@/components/workflows/sidebar/trigger/schedule/CalendarDateScheduleComponent.vue"
import WeekDaysScheduleComponent from "@/components/workflows/sidebar/trigger/schedule/WeekDaysScheduleComponent.vue"
import TimeOfDayScheduleComponent from "@/components/workflows/sidebar/trigger/schedule/TimeOfDayScheduleComponent.vue"

const emit = defineEmits(["changeAction"])

const scheduleTypes = [
    { title: "Calendar date", example: "Trigger in 03/21/2023 at 12:00 PM", component: CalendarDateScheduleComponent },
    { title: "Days of week", example: "Trigger every Monday", component: WeekDaysScheduleComponent },
    { title: "Time of day", example: "Trigger at 12:00 PM EST", component: TimeOfDayScheduleComponent },
]

function selectScheduleType(sidebarComponent: Component) {
    emit("changeAction", {
        sidebarComponent,
    })
}
</script>

<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0 5C0 2.23858 2.23858 0 5 0H15C17.7614 0 20 2.23858 20 5V15C20 17.7614 17.7614 20 15 20H5C2.23858 20 0 17.7614 0 15V5Z"
            fill="#9CA3AF"
        />
        <path
            d="M10 7.5V10L11.875 11.875M15.625 10C15.625 13.1066 13.1066 15.625 10 15.625C6.8934 15.625 4.375 13.1066 4.375 10C4.375 6.8934 6.8934 4.375 10 4.375C13.1066 4.375 15.625 6.8934 15.625 10Z"
            stroke="white"
            stroke-width="0.9375"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<template>
    <GgmsCascadeSelect
        :options="options"
        label="Merge fields"
        placeholder="Merge Fields"
        @select="addMergeField"
    ></GgmsCascadeSelect>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { capitalize } from "@/shared/utils/helpers"
import { useConfigStore } from "@/stores/config"
import GgmsCascadeSelect from "./GgmsCascadeSelect.vue"
import { Attribute } from "@/shared/models/attributes"
import { Prototype } from "@/shared/models/config"
import { CascadeChildOption } from "@/shared/models/cascade-option"

interface Props {
    specialVariable?: string
    isTemplateTypeEmail?: boolean
}

const props = defineProps<Props>()

const configStore = useConfigStore()
const emit = defineEmits(["addMergeField"])

const categories = computed(() =>
    configStore.prototypes.filter((prototype) => ["contact", "agent", "agency"].includes(prototype.typeName))
)

const specialVariableMap = {
    "Welcome user": {
        name: "Verify Email URL",
        value: "user.verifyEmailUrl",
        hasFallback: false,
    },
    "Forgotten password": {
        name: "Reset Password URL",
        value: "user.resetPasswordUrl",
        hasFallback: false,
    },
    "SMS daily quota reached": {
        name: "Setup Business Profile URL",
        value: "user.setupBusinessProfileURL",
        hasFallback: false,
    },
    "Contact import finished": [
        {
            name: "Contact Import Status",
            value: "user.status",
            hasFallback: false,
        },
        {
            name: "Number of inserted contacts",
            value: "user.inserted",
            hasFallback: false,
        },
        {
            name: "Errors",
            value: "user.errors",
            hasFallback: false,
        },
    ],
    "Invite agent": {
        name: "Invite Link",
        value: "user.inviteLink",
        hasFallback: false,
    },
}

const systemVariables = {
    Unsubscribed: {
        name: "Unsubscribe Link",
        value: "{unsubscribeLink}",
        hasFallback: false,
    },
}

const options = computed(() => {
    const mergeFields = categories.value.map((category) => {
        return {
            name: capitalize(category.typeName),
            value: category.typeName,
            children: setChildElements(category),
        }
    })
    if (props.isTemplateTypeEmail) {
        mergeFields.push({
            name: "System",
            value: "system",
            children: Object.values(systemVariables),
        })
    }
    if (!props.specialVariable) {
        return mergeFields
    }
    mergeFields.push({
        name: "User",
        value: "user",
        children: setSpeacialVariables(),
    })
    return mergeFields
})

function setSpeacialVariables() {
    const specialVariable = specialVariableMap[props.specialVariable]
    if (Array.isArray(specialVariable)) {
        return specialVariable
    }
    return [specialVariable]
}

function addMergeField(option: CascadeChildOption) {
    let optionValue = option.value
    if (option.hasFallback) {
        optionValue = `${optionValue}:<fallback>`
    }
    emit("addMergeField", `{{${optionValue}}}`)
}

function setChildElements(category: Prototype) {
    const children =
        category.attributes?.map((attribute: Attribute) => {
            return {
                name: attribute.displayName as string,
                value: category.typeName ? `${category.typeName}.${attribute.name}` : (attribute.name as string),
                hasFallback: true,
            }
        }) ?? []
    if (category.typeName === "agent") {
        children.unshift({
            name: "Signature",
            value: "{ templateRenderedSignature }",
            hasFallback: false,
        })
    }
    return children
}
</script>

import { defineStore } from "pinia"
import { useJobsStore } from "@/stores/jobs"
import { useGridCollectionStore } from "@/stores/grid-collection"
import { SocketClientWrapper } from "@/shared/services/socket"
import { ToastService } from "@/shared/services/toast"
import { JobPayload, Job } from "@/shared/models/jobs"
import { ToastModel } from "@/shared/models/toast-model"
import { TaskNotificationReminder } from "@/shared/models/task"
import { AppointmentNotificationReminder } from "@/shared/models/appointment"
import { createAppointmentMessage, formatHumanReadableDate } from "@/shared/utils/helpers"

export const useSocketStore = defineStore("socket", {
    state: () => ({
        socketService: {} as SocketClientWrapper,
        inProgressJobs: {} as Record<string, Job>,
        taskReminders: {} as Record<string, TaskNotificationReminder>,
        toastService: new ToastService(),
        jobStore: useJobsStore(),
        gridCollectionStore: useGridCollectionStore(),
        appointments: [] as AppointmentNotificationReminder[],
    }),
    actions: {
        connectSocket() {
            this.socketService = SocketClientWrapper.getInstance(`${process.env.VUE_APP_BASE_API_URL}/jobs`)
            this.socketService.connect()
            this.socketService.on("connect", () => {
                this.socketService.emit("join")
            })
        },

        connectJobs() {
            this.socketService.on("taskReminder", (tasks: { taskDetails: TaskNotificationReminder }[]) => {
                tasks.forEach((item: { taskDetails: TaskNotificationReminder }) => {
                    const { taskDetails: task } = item
                    this.taskReminders[task._id] = task
                    const toastPayload = {
                        type: "notification",
                        message: task.title,
                        persistent: true,
                        id: task._id,
                    } as ToastModel

                    const existingToast = this.toastService.getToasts().find((toast) => toast.id === task._id)
                    if (existingToast) {
                        this.toastService.updateToast(task._id, toastPayload)
                    } else {
                        this.toastService.addToast(toastPayload)
                    }
                })
            })

            this.socketService.on("appointmentCreated", (appointment) => {
                const formattedDate = formatHumanReadableDate(new Date(appointment.appointmentDate.startAt), false)
                const startTime = formatHumanReadableDate(new Date(appointment.appointmentDate.startAt), true).split(
                    " "
                )[1]
                const endTime = formatHumanReadableDate(new Date(appointment.appointmentDate.endAt), true).split(" ")[1]

                // Use utility function to create the formatted HTML message for the sidebar
                const sidebarMessage = createAppointmentMessage(
                    appointment.contactUserName,
                    formattedDate,
                    startTime,
                    endTime
                )

                // Add the new appointment to state
                this.appointments.push({
                    title: appointment.title,
                    description: sidebarMessage,
                    _id: appointment._id,
                })

                // Generate the toast message dynamically, using a similar logic but without HTML tags
                let toastMessage = `Appointment for ${appointment.contactUserName} was created. It will take place `
                if (formattedDate === "Today" || formattedDate === "Tomorrow") {
                    toastMessage += `${formattedDate} from ${startTime} to ${endTime}`
                } else {
                    toastMessage += `on ${formattedDate} from ${startTime} to ${endTime}`
                }

                const toastPayload = {
                    type: "notification",
                    message: toastMessage,
                    persistent: true,
                    id: appointment._id,
                } as ToastModel

                const existingToast = this.toastService.getToasts().find((toast) => toast.id === appointment._id)
                if (existingToast) {
                    this.toastService.updateToast(appointment._id, toastPayload)
                } else {
                    this.toastService.addToast(toastPayload)
                }
            })

            // Progress for jobs
            this.socketService.on("updatedJob", (jobs: JobPayload[]) => {
                jobs.forEach(({ job, modifiedCount, totalCount }) => {
                    if (!job || job.progress === undefined) {
                        return
                    }

                    this.inProgressJobs[job._id] = job
                    this.jobStore.tableState.data.forEach((iterator) => {
                        if (iterator._id === job._id) {
                            iterator.progress = job.progress
                        }
                    })

                    const toastPayload = {
                        type: "notification",
                        message: job.title,
                        persistent: true,
                        percentage: job.progress,
                        id: job._id,
                    } as ToastModel

                    const existingToast = this.toastService.getToasts().find((toast) => toast.id === job._id)
                    if (existingToast) {
                        this.toastService.updateToast(job._id, toastPayload)
                    } else if (job.progress < 100) {
                        this.toastService.addToast(toastPayload)
                    }

                    if (job.progress === 100) {
                        delete this.inProgressJobs[job._id]
                        if (modifiedCount && totalCount && modifiedCount < totalCount) {
                            this.toastService.addToast({
                                type: "success",
                                message: `Updated ${modifiedCount}/${totalCount} contacts with tags because of your permissions.`,
                            })
                        }
                        if (job?.title?.toLowerCase().includes("contacts")) {
                            this.jobStore.jobFinishedForEntity = "contact"
                        }
                        if (job?.title?.toLowerCase().includes("tasks")) {
                            this.jobStore.jobFinishedForEntity = "task"
                        }
                        if (job?.title?.toLowerCase().includes("listings")) {
                            this.jobStore.jobFinishedForEntity = "listing"
                        }
                        if (job?.title?.toLowerCase().includes("forms")) {
                            this.jobStore.jobFinishedForEntity = "form"
                        }
                    }
                })
            })
        },

        disconnectSocket() {
            this.socketService.disconnect()
        },

        addJob(job: Job) {
            this.inProgressJobs[job._id] = job
        },
    },
})

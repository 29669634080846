<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" rx="6" fill="#2563EB" />
        <path
            d="M9.49935 6.16667H7.83268C6.91221 6.16667 6.16602 6.91286 6.16602 7.83333V17.8333C6.16602 18.7538 6.91221 19.5 7.83268 19.5H16.166C17.0865 19.5 17.8327 18.7538 17.8327 17.8333V7.83333C17.8327 6.91286 17.0865 6.16667 16.166 6.16667H14.4993M9.49935 6.16667C9.49935 7.08714 10.2455 7.83333 11.166 7.83333H12.8327C13.7532 7.83333 14.4993 7.08714 14.4993 6.16667M9.49935 6.16667C9.49935 5.24619 10.2455 4.5 11.166 4.5H12.8327C13.7532 4.5 14.4993 5.24619 14.4993 6.16667M11.9993 12H14.4993M11.9993 15.3333H14.4993M9.49935 12H9.50768M9.49935 15.3333H9.50768"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
        />
    </svg>
</template>

import { Template, TemplatePayload, TemplateTableState } from "@/shared/models/template"
import { TemplateService } from "@/shared/services/template"
import { ToastService } from "@/shared/services/toast"
import { defineStore } from "pinia"
import { GgmsError } from "@/shared/services/client"
import { useGridCollectionStore } from "@/stores/grid-collection"
import { Agent } from "../shared/models/agent"
import { Collection } from "../shared/models/collection"

export const useTemplateStore = defineStore("templates", {
    state: () => ({
        tableState: {
            data: [],
            currentPage: 0,
            lastPage: 0,
            pageLength: 50,
            numberOfPages: 0,
            total: 0,
            isLoading: false,
            templateType: "",
            search: "",
            firstIds: [],
        } as TemplateTableState,
        templateService: new TemplateService(),
        selectedData: [] as Template[],
        toastService: new ToastService(),
        validationError: {} as GgmsError,
        gridCollectionStore: useGridCollectionStore(),
        newTemplate: {} as Template,
    }),
    actions: {
        async getTemplates() {
            try {
                this.tableState.isLoading = true
                this.validationError = {} as GgmsError
                const filters: { [key: string]: string | number } = {
                    start: this.tableState.currentPage,
                    length: this.tableState.pageLength,
                }
                this.tableState.search ? (filters.search = this.tableState.search) : delete filters.search
                this.tableState.templateType
                    ? (filters.templateType = this.tableState.templateType)
                    : delete filters.templateType

                let lastId = null
                let offset = null

                // If we're going to the next page, use keyset pagination.
                if (this.tableState.currentPage === (this.tableState?.lastPage ?? 0) + 1) {
                    lastId =
                        this.tableState.data.length > 0
                            ? this.tableState.data[this.tableState.data?.length - 1]._id
                            : null
                }
                // If we're jumping to a specific page, use offset-based pagination.
                else if (
                    this.tableState.currentPage !== (this.tableState?.lastPage ?? 0) + 1 &&
                    this.tableState.currentPage > 1
                ) {
                    offset = (this.tableState.currentPage - 1) * this.tableState.pageLength
                }

                const filtersGroups = this.gridCollectionStore?.grid?.filtersGroups?.length
                    ? encodeURIComponent(JSON.stringify(this.gridCollectionStore.grid.filtersGroups))
                    : undefined

                const response = await this.templateService.getAll({
                    ...filters,
                    lastId,
                    offset,
                    filtersGroups,
                })
                this.tableState.selectedData = []
                this.tableState.data = response.data
                this.tableState.total = response.total
                this.tableState.numberOfPages = Math.ceil(response.total / this.tableState.pageLength)
                this.tableState.lastPage = this.tableState.currentPage
            } finally {
                this.tableState.isLoading = false
            }
        },

        resetTableState() {
            this.tableState.data = []
            this.tableState.total = 0
            this.tableState.selectedData = []
            this.tableState.numberOfPages = Math.ceil(1)
        },

        async getTemplatesGrid() {
            try {
                this.tableState.isLoading = true
                const response = await this.gridCollectionStore.getGrid("templates")

                await this.getTemplates()
                return response
            } finally {
                this.tableState.isLoading = false
            }
        },

        clearFilters() {
            this.gridCollectionStore.grid.filtersGroups = []
            this.gridCollectionStore.filtersGroups = []

            this.updateTemplatesGrid()
        },

        async updateTemplatesGrid() {
            try {
                this.tableState.isLoading = true
                if (!this.gridCollectionStore.grid) return
                this.gridCollectionStore.grid.filtersGroups = this.gridCollectionStore.filtersGroups || []

                const response = await this.gridCollectionStore.updateGrid("templates", this.gridCollectionStore.grid)
                this.gridCollectionStore.gridResponse = response
                this.gridCollectionStore.grid = this.gridCollectionStore.gridResponse.grid
                this.gridCollectionStore.filtersGroups = this.gridCollectionStore.grid.filtersGroups

                await this.getTemplates()
            } finally {
                this.tableState.isLoading = false
            }
        },

        async createTemplate(payload: TemplatePayload, toastMessage?: string) {
            try {
                this.tableState.isLoading = true
                this.validationError = {} as GgmsError
                const response = await this.templateService.createTemplate(payload)
                if (response) {
                    response.templates.map((template) => this.tableState.data.unshift(template))
                    this.tableState.total += response.templates.length
                    const subjectTemplate = response.templates[0].subject
                        ? `${response.templates[0].subject} template`
                        : "Template"
                    this.toastService.addToast({
                        type: "success",
                        message: toastMessage || `${subjectTemplate} created`,
                    })
                }
                return response
            } catch (error) {
                this.validationError = error as GgmsError
            } finally {
                // it is required in order to not submit the request multiple times
                setTimeout(() => {
                    this.tableState.isLoading = false
                }, 500)
            }
        },

        async updateTemplate(templateId: string, payload: TemplatePayload) {
            try {
                this.tableState.isLoading = true
                this.validationError = {} as GgmsError
                const { template } = await this.templateService.updateTemplate(templateId, payload)
                if (template) {
                    const index = this.tableState.data.findIndex((template) => template._id === templateId)
                    this.tableState.data[index] = template
                    const subjectTemplate = template.subject ? `${template.subject} template` : "Template"
                    this.toastService.addToast({
                        type: "success",
                        message: `${subjectTemplate} updated`,
                    })
                }
                return template
            } catch (error) {
                this.validationError = error as GgmsError
            } finally {
                // it is required in order to not submit the request multiple times
                setTimeout(() => {
                    this.tableState.isLoading = false
                }, 500)
            }
        },

        async deleteTemplate(templateId: string) {
            try {
                this.tableState.isLoading = true
                this.validationError = {} as GgmsError
                const { category } = await this.templateService.deleteTemplate(templateId)
                if (category) {
                    const index = this.tableState.data.findIndex((template) => template._id === templateId)
                    this.tableState.data.splice(index, 1)
                    this.tableState.total--
                    const subjectTemplate = category.subject ? `${category.subject} template` : "Template"
                    this.toastService.addToast({
                        type: "success",
                        message: `${subjectTemplate} removed`,
                    })
                }
                return category
            } catch (error) {
                this.validationError = error as GgmsError
            } finally {
                // it is required in order to not submit the request multiple times
                setTimeout(() => {
                    this.tableState.isLoading = false
                }, 500)
            }
        },

        async checkTemplateList(typename: string, entityIds: string[]) {
            try {
                return this.templateService.checkTemplateList(typename, entityIds)
            } catch (error) {
                const err = error as GgmsError
                if (err.code === "ValidationError") {
                    this.toastService.addToast({
                        type: "error",
                        message: err.message,
                    })
                }
            }
        },

        isValidationForDisplayName() {
            return (
                this.validationError?.fullMessage?.includes("Display") ||
                this.validationError?.message?.includes("Display")
            )
        },
    },
})

<template>
    <div class="flex flex-col">
        <div v-if="!isEntitySelected" class="flex flex-col gap-y-3">
            <span class="text-base leading-6 font-medium text-black">Object </span>
            <div class="border border-gray-200 rounded-lg shadow-sm bg-white">
                <div v-for="entity in entitiesList" :key="entity.typeName" class="flex flex-col gap-1 px-2 pt-2">
                    <div
                        class="p-2.5 text-gray-900 cursor-pointer hover:bg-gray-100 rounded-lg mb-2"
                        @click.stop="selectEntity(entity.typeName)"
                    >
                        {{ entity.displayName }}
                    </div>
                </div>
            </div>
        </div>

        <div v-else class="flex flex-col">
            <span class="text-base leading-6 font-medium text-black"
                >{{ getEntityDisplayName(workflowStore.branches[0].selectedEntity) }} property</span
            >
            <GgmsDropdown
                v-model="workflowStore.modelValue"
                class="mt-3 w-full text-ellipsis"
                :options="prototypes[0].attributes"
                optionLabel="displayName"
                optionValue="name"
                placeholder="Select object property"
                panelClass="w-20 text-ellipsis"
            ></GgmsDropdown>
        </div>

        <div v-if="workflowStore.modelValue" class="flex flex-col mb-4">
            <hr class="bg-gray-200 mt-8 mb-4 w-[445px] -mx-6" />

            <p class="text-base leading-6 font-medium text-black pt-4">
                If '{{
                    workflowStore.branches[0]?.selectedFilter?.displayName ||
                    workflowStore.branches[0]?.selectedFilter?.[0]?.displayName ||
                    "Object property"
                }}' :
            </p>
        </div>
        <FilterRule
            v-if="isCurrentFilterStep('filterRule')"
            :filter="workflowStore.branches[0]?.selectedFilter!"
            :isUpdated="isEditFilter"
            :showHeader="false"
            @applyFilter="applyFilter"
            @cancel="cancelFilter"
        />
        <FilterList
            v-if="isCurrentFilterStep('filterList')"
            :savedFiltersGroups="workflowStore.branches[0].filtersGroups"
            :isWorkflow="true"
            @addNewFilter="openFilterRulesList"
            @editFilter="editFilter"
            @clearFilters="clearFilters"
            @removeGroup="workflowStore.areButtonDisabled = false"
            @removeFilter="workflowStore.areButtonDisabled = false"
        />
        <div v-if="isEntitySelected">
            <hr class="bg-gray-200 mt-8 mb-4 w-[445px] -mx-6" />
            <p class="text-base leading-6 font-medium text-black py-4">
                If ‘{{ workflowStore.branches[0]?.selectedFilter?.displayName || "Object property" }}’ is none of the
                above go here:
            </p>
            <GgmsInput
                v-model="workflowStore.noneBranch.value"
                label="‘None’ branch name"
                placeholder="None"
            ></GgmsInput>
        </div>
    </div>
</template>
<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue"
import GgmsDropdown from "@/components/GgmsDropdown.vue"
import FilterRule from "@/components/filters/FilterRule.vue"
import GgmsInput from "@/components/GgmsInput.vue"
import FilterList from "@/components/filters/FilterList.vue"
import { useGridCollectionStore } from "@/stores/grid-collection"
import { useWorkflowStore } from "@/stores/workflow"
import { Filter } from "@/shared/models/filter"

const workflowStore = useWorkflowStore()
const gridCollectionStore = useGridCollectionStore()

defineEmits(["setButtonsStatus"])

const prototypes = computed(() => {
    gridCollectionStore.getPrototypesByPage("contacts")
    return gridCollectionStore.prototypes
})

const isEditFilter = ref(false)
const isEntitySelected = ref(false)

const entitiesList = ref([
    { displayName: "Contact", typeName: "contacts" },
    { displayName: "Call", typeName: "calls" },
    { displayName: "Form", typeName: "forms" },
    { displayName: "Workflow", typeName: "workflows" },
])

function isCurrentFilterStep(step: string) {
    return workflowStore.branches[0]?.currentStep === step
}

function selectEntity(entity: string) {
    isEntitySelected.value = true
    gridCollectionStore.getPrototypesByPage(entity)
    workflowStore.branches[0].selectedEntity = entity
    workflowStore.branches[0].currentStep = "attributeList"
}

function getEntityDisplayName(selectedEntity: string) {
    return entitiesList.value.find((entity) => entity.typeName === selectedEntity)?.displayName
}

function setPrototypesByCollectionType() {
    const lastCollectionType = gridCollectionStore.collectionTypeHistory.at(-1) as string
    gridCollectionStore.getPrototypesByPage(lastCollectionType)
}

function applyFilter(filter: Filter) {
    if (["body"].includes(filter.field)) {
        filter.field = `messages.${filter.field}`
    }

    isEditFilter.value ? editFilterGroup(filter) : addFilterToGroup(filter)
    openFiltersList()
    workflowStore.areButtonDisabled = false
}

function addFilterToGroup(filter: Filter) {
    // TODO - we need to discuss about object type
    if (filter.field === "phones") {
        filter.field = "phones.number"
    } else if (filter.field === "emails") {
        filter.field = "emails.emailAddress"
    }
    const branch = workflowStore.branches[0]
    if (!branch) {
        return
    }

    if (!branch?.filtersGroups?.at(branch.selectedFilterGroup!)) {
        workflowStore.branches.at(0)?.filtersGroups?.push({ filters: [filter] })
        return
    }
    workflowStore.branches.at(0)?.filtersGroups?.at(branch.selectedFilterGroup!)?.filters.push(filter)
}

function editFilterGroup(filter: Filter) {
    if (filter.field === "phones") {
        filter.field = "phones.number"
    } else if (filter.field === "emails") {
        filter.field = "emails.emailAddress"
    }
    const branch = workflowStore.branches[0]
    workflowStore.branches[0].filtersGroups[branch.selectedFilterGroup].filters[branch.selectedFilterIndex] = filter
}

function openFiltersList() {
    workflowStore.branches[0].currentStep = "filterList"
}

function cancelFilter() {
    resetSelectedFilter()
    openFiltersList()
}
function resetSelectedFilter() {
    workflowStore.branches[0].selectedFilter = {
        field: "",
        type: "",
        rule: "",
        value: "",
    }
}

function openFilterRulesList() {
    const property = getAttributeByName(workflowStore.modelValue)
    workflowStore.branches[0].selectedFilter = {
        field: property?.name,
        type: property?.type,
        displayName: property?.displayName,
        value: "",
        rule: "",
    }

    workflowStore.branches[0].currentStep = "filterRule"
    isEditFilter.value = false
}

function editFilter(filter: Filter, filterIndex: number) {
    isEditFilter.value = true
    workflowStore.branches[0].selectedFilter = {
        field: filter.field,
        type: filter.type as string,
        value: filter.value as string,
        rule: filter.rule,
        displayName: filter.displayName as string,
    }
    workflowStore.branches[0].currentStep = "filterRule"
    workflowStore.branches[0].selectedFilterIndex = filterIndex
}

function clearBranchFilters() {
    workflowStore.branches[0].currentStep = undefined
    workflowStore.branches[0].selectedFilter = {
        field: "",
        type: "",
        rule: "",
        value: "",
        displayName: "",
    }
    workflowStore.branches[0].filtersGroups = []
    workflowStore.areButtonDisabled = true
}

async function clearFilters() {
    workflowStore.branches[0].currentStep = undefined
    clearBranchFilters()
    workflowStore.branches[0].selectedFilter = {
        field: "",
        type: "",
        rule: "",
        value: "",
        displayName: "",
    }
    workflowStore.modelValue = ""
    workflowStore.areButtonDisabled = true
}

function getAttributeByName(name: string) {
    return prototypes.value[0].attributes.find((attribute) => attribute.name === name)
}

onMounted(() => {
    if (workflowStore.branches[0].selectedEntity) {
        isEntitySelected.value = true
    }
})

watch(
    () => workflowStore.noneBranch?.value,
    () => (workflowStore.areButtonDisabled = false)
)

watch(
    () => workflowStore.modelValue,
    () => {
        if (!workflowStore.modelValue) {
            return
        }
        const property = getAttributeByName(workflowStore.modelValue)
        workflowStore.branches[0].selectedFilter = {
            field: property?.name,
            type: property?.type,
            displayName: property?.displayName,
            rule: "",
            value: "",
        }
        workflowStore.branches[0].currentStep = "filterRule"
        workflowStore.branches[0].filtersGroups = []
    }
)
</script>

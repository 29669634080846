<template>
    <div class="flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-white">
        <div class="flex-1 flex flex-col overflow-y-auto px-2 py-5 no-scrollbar">
            <GgmsAgencySelect :collapsed="props.collapsed" />
            <nav class="flex-1 bg-white space-y-1" aria-label="Sidebar">
                <div
                    v-for="(item, index) in props.items"
                    :key="item.name"
                    :to="item.path"
                    :class="getClassesItem(item)"
                    @click="onItemClick(item)"
                    role="button"
                >
                    <GgmsDisclosureForNavigation
                        v-if="item.children && !props.collapsed"
                        :key="item.name"
                        :ref="(el) => (disclosures[index] = el)"
                        :children-items="item.children"
                        :name="item.name"
                        class="flex w-full space-y-1"
                    >
                        <template #header="{ isOpen }">
                            <component
                                :is="item.icon"
                                :class="[getClassesIcon(item), getParentIconClass(isOpen)]"
                                :stroke="getParentIconStroke(isOpen)"
                                aria-hidden="true"
                            />
                            <span
                                v-if="!props.collapsed"
                                class="flex-1 self-center text-sm font-medium"
                                :class="getParentTextClass(isOpen)"
                            >
                                {{ item.name }}
                            </span>
                        </template>
                        <template #content>
                            <router-link
                                v-for="child in item.children"
                                :to="child.path"
                                :key="child.name"
                                :class="getClassesChildItem(child)"
                                @click="closeDisclosure(index)"
                            >
                                <span class="flex-1 text-sm font-medium">
                                    {{ child.name }}
                                </span>
                            </router-link>
                        </template>
                    </GgmsDisclosureForNavigation>
                    <router-link
                        v-else-if="!item.children && !props.collapsed"
                        :to="getItemPath(item)"
                        :class="[
                            'flex',
                            'w-full',
                            'items-center',
                            'py-2',
                            props.collapsed ? 'px-3' : 'px-2',
                            'lg:px-3',
                            '2xl:px-3',
                        ]"
                        @click="closeDisclosure"
                    >
                        <component
                            :is="item.icon"
                            :class="[
                                'flex-shrink-0 h-6 w-6 mx-auto',
                                props.collapsed ? 'block' : 'mr-3',
                                getClassesIcon(item),
                            ]"
                            aria-hidden="true"
                        />
                        <span v-if="!props.collapsed" class="flex-1 text-sm font-medium">
                            {{ item.name }}
                        </span>
                    </router-link>
                    <router-link
                        v-else
                        :to="getItemPath(item)"
                        :class="[
                            'flex',
                            'w-full',
                            'items-center',
                            'py-2',
                            props.collapsed ? 'px-3' : 'px-2',
                            'lg:px-3',
                            '2xl:px-3',
                        ]"
                        v-tooltip.right="{ value: item.name, class: 'font-medium text-xs  px-3 py-2' }"
                        @click="closeDisclosure"
                    >
                        <component
                            :is="item.icon"
                            :class="[
                                'flex-shrink-0 h-6 w-6 mx-auto',
                                props.collapsed ? 'block' : 'mr-3',
                                getClassesIcon(item),
                            ]"
                            aria-hidden="true"
                        />
                        <span v-if="!props.collapsed" class="flex-1 text-sm font-medium">
                            {{ item.name }}
                        </span>
                    </router-link>
                </div>
            </nav>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref } from "vue"
import { useRoute } from "vue-router"
import GgmsDisclosureForNavigation from "@/components/GgmsDisclosureForNavigation.vue"
import GgmsAgencySelect from "@/components/GgmsAgencySelect.vue"
import { useNavigationSidebarStore } from "@/stores/navigation-sidebar"
import { NavigationChildItem, NavigationItem } from "@/shared/models/navigation"

const route = useRoute()

const navigationSidebarStore = useNavigationSidebarStore()

interface Props {
    items: NavigationItem[]
    collapsed: boolean
}

const props = defineProps<Props>()

const emit = defineEmits(["menuItemClick"])

const collapsed = ref(props.collapsed)

const disclosures = ref([] as any[])

function getParentIconClass(isOpen: boolean) {
    return isOpen ? "stroke-primary-color-600" : "stroke-gray-400 group-hover:stroke-gray-500"
}

function getParentIconStroke(isOpen: boolean) {
    return isOpen ? "stroke-primary-color-600" : "stroke-gray-400 group-hover:stroke-gray-500"
}

function getParentTextClass(isOpen: boolean) {
    return isOpen ? "text-primary-color-600" : ""
}

function changeCollapsedState(collapsedValue: boolean) {
    collapsed.value = collapsedValue
}

function getClassesItem(item: NavigationItem) {
    return [
        checkActiveRoute(item.path as string)
            ? "bg-primary-color-600 text-white hover:bg-primary-color-700"
            : "text-gray-600 hover:text-gray-900 hover:bg-gray-50",
        "group flex items-center text-sm font-medium rounded-md",
    ]
}

function getClassesChildItem(item: NavigationChildItem) {
    return [
        checkActiveRoute(item.path as string)
            ? "bg-primary-color-600 text-white hover:bg-primary-color-700"
            : "text-gray-600 hover:text-gray-900 hover:bg-gray-50",
        "group flex items-center px-10 py-2 text-sm font-medium rounded-md",
    ]
}

function getClassesIcon(item: NavigationItem) {
    return [
        checkActiveRoute(item.path as string) ? "text-white" : "text-gray-400 group-hover:text-gray-500",
        "mr-3 flex-shrink-0 h-6 w-6",
    ]
}

function checkActiveRoute(path: string) {
    return route.path === path || route.path.includes(path)
}

function closeDisclosure(index?: number) {
    navigationSidebarStore.toggleSidebar(false)
    disclosures.value.forEach((disclosure, disclosureIndex) => {
        if (disclosureIndex === index) {
            return
        }
        disclosure?.closeDisclosure()
    })
}

function onItemClick(item: NavigationItem) {
    if (!props.collapsed) return
    emit("menuItemClick", item)
}

function getItemPath(item: NavigationItem) {
    return item?.path || ""
}
</script>
<style scoped>
/* Styles to remove the scrollbar from navigation */
.no-scrollbar {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
}

.no-scrollbar::-webkit-scrollbar {
    display: none; /* WebKit browsers */
}
/* ---------------------------------------------- /*/
</style>

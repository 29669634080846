import Crud from "./crud"
import { Tag } from "@/shared/models/tag"
import { Job } from "@/shared/models/jobs"
import { Client } from "@/shared/services/client"

const client = new Client()

export class TagService extends Crud<Tag> {
    constructor() {
        super("crm/tags")
    }

    createTag(tag: Tag): Promise<{ job: Job }> {
        return client.post(`${this.endpoint}`, tag)
    }

    updateTag(id: string, tag: Partial<Tag>): Promise<{ job: { _id: string } }> {
        return client.patch(`${this.endpoint}/${id}`, tag)
    }

    deleteTag(id: string, acknowledged?: boolean): Promise<{ id: string }> {
        const params = acknowledged ? { acknowledged } : {}
        return client.delete(`${this.endpoint}/${id}`, {
            params,
        })
    }

    deleteTags(ids: string[], all: boolean, acknowledged?: boolean): Promise<{ ids: string[] }> {
        return client.post(`${this.endpoint}/bulk/delete`, {
            ids,
            all,
            acknowledged: acknowledged || undefined,
        })
    }

    bulkUpdateTags(ids: string[], all: boolean, updates: Partial<Tag>): Promise<{ updatedCount: number }> {
        return client.post(`${this.endpoint}/bulk/update`, {
            ids,
            all,
            updates,
        })
    }

    getCachedTags(): Promise<{ data: Tag[] }> {
        return client.get(`${this.endpoint}/all`)
    }

    checkTagList(typeName: string, entityIds: string[]) {
        return client.get(`${this.endpoint}/listCheck`, { params: { typeName, entityIds } })
    }
}

import Crud from "./crud"
import { Client } from "@/shared/services/client"
import { Agent } from "@/shared/models/agent"
import { PagedResponse } from "@/shared/models/paged-response"
import { TableQueryParams } from "@/shared/models/table-query-params"
import { ResponsePresignedUrl, UploadItem } from "../models/upload-file"
import { User } from "../models/user"

const client = new Client()

export class AgentService extends Crud<Agent> {
    constructor() {
        super("/crm/agents")
    }

    search(queryParams: TableQueryParams): Promise<PagedResponse<Agent>> {
        return client.get(`${this.endpoint}/autocomplete`, { params: queryParams })
    }

    searchPermissions(search: string, notInIds: string | undefined, limit: undefined | number) {
        return client.get(`${this.endpoint}/autocomplete/permissions`, {
            params: {
                search,
                limit,
                notInIds,
            },
        })
    }

    searchRecipients(search: string, notInIds: string | undefined, limit: undefined | number) {
        return client.get(`${this.endpoint}/autocomplete/recipients`, {
            params: {
                search,
                limit,
                notInIds,
            },
        })
    }

    validatePassword(password: string): Promise<{ isValid: boolean }> {
        return client.post(`${this.endpoint}/validate-password`, { password })
    }

    changePassword(password: string): Promise<boolean> {
        return client.post(`${this.endpoint}/change-password`, { password })
    }

    requestPostDataForFileUpload(uploads: UploadItem[]): Promise<ResponsePresignedUrl> {
        return client.post(`${this.endpoint}/files`, { uploads })
    }

    uploadFiles(endpoint: string, data: FormData) {
        return client.postFile(endpoint, data)
    }

    updateAgent(agent: Partial<User>): Promise<{ agent: User }> {
        return client.put(`${this.endpoint}`, agent)
    }

    updateAgentRole(agentId: string, roleName: string): Promise<{ agent: User }> {
        return client.put(`${this.endpoint}/${agentId}/role`, { role: { name: roleName } })
    }

    getMe(): Promise<{ agent: User }> {
        return client.get(`${this.endpoint}/me`)
    }

    inviteAgent(email: string, role: string): Promise<{ agent: User }> {
        return client.post(this.endpoint, { email, role })
    }

    disableAgent(id: string, agencyId: string): Promise<{ agent: User }> {
        return client.post(`${this.endpoint}/disable/${id}?agencyId=${agencyId}`)
    }

    removeAgent(id: string, agencyId: string): Promise<{ agent: User }> {
        return client.delete(`${this.endpoint}/remove/${id}?agencyId=${agencyId}`)
    }

    getAgentByEmailCode(code: string) {
        return client.get(`${this.endpoint}/email-code`, { params: { token: code } })
    }

    resendEmailVerification() {
        return client.post(`${this.endpoint}/email-verification`)
    }

    checkAgentList(typeName: string, entityIds: string[]) {
        return client.get(`${this.endpoint}/listCheck`, { params: { typeName, entityIds } })
    }
}

<template>
    <Sidebar v-model:visible="isPhoneSidebarVisible" position="right" autoZIndex :showCloseIcon="false">
        <template #header
            ><div class="flex items-center justify-between w-full">
                <p class="text-white text-lg font-medium">Phone dialer</p>
                <LoginIcon
                    v-if="isPhoneCallInProgress"
                    class="h-6 w-6 text-white cursor-pointer rotate-180"
                    @click="closeDialog"
                ></LoginIcon>
                <XIcon v-else class="h-6 w-6 text-white cursor-pointer" @click="closeDialog" />
            </div>
        </template>
        <component :is="phoneCallComponent" />
    </Sidebar>
</template>

<script lang="ts" setup>
import { computed, defineAsyncComponent, onMounted } from "vue"
import { XIcon } from "@heroicons/vue/outline"
import { LoginIcon } from "@heroicons/vue/solid"
import { usePhoneCallStore } from "@/stores/phone-call"
import { useFormStore } from "@/stores/form"
import { PhoneCall } from "@/shared/models/phone-call"
import { Contact } from "@/shared/models/contact"

interface Props {
    modelValue: boolean
}

const props = defineProps<Props>()
const emit = defineEmits(["update:modelValue"])

const phoneCallStore = usePhoneCallStore()

const isPhoneCallInProgress = computed(() => ["calling", "ongoing"].includes(phoneCallStore.phoneCallState))

const phoneCallComponent = computed(() => {
    if (["calling", "ongoing", "finished"].includes(phoneCallStore.phoneCallState)) {
        return phoneComponents.inProgress
    }
    return phoneComponents[phoneCallStore.phoneCallState]
})

const phoneComponents = {
    idle: defineAsyncComponent(() => import("@/components/phone-sidebar/PhoneCallIdle.vue")),
    inProgress: defineAsyncComponent(() => import("@/components/phone-sidebar/PhoneCallInProgress.vue")),
    message: defineAsyncComponent(() => import("@/components/phone-sidebar/PhoneTextMessage.vue")),
}

const isPhoneSidebarVisible = computed({
    get: () => props.modelValue,
    set: (value: boolean) => {
        emit("update:modelValue", value)
    },
})

function closeDialog() {
    if (!isPhoneCallInProgress.value) {
        phoneCallStore.currentPhoneCall = {} as Partial<PhoneCall>
        phoneCallStore.phoneCallState = "idle"
    }
    isPhoneSidebarVisible.value = false
    phoneCallStore.selectedContact = {} as Contact
}
</script>

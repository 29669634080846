<template>
    <div :class="getWrapperStyleInline()">
        <div class="flex" :class="getRequiredStyleInline">
            <label :for="name" :class="[getLabelStyleInline(), 'block text-sm font-medium text-gray-700 ']">
                {{ label }}</label
            >
            <span v-if="required" :class="starStyle">*</span>
        </div>
        <p v-if="description" class="text-sm text-gray-500 my-4">
            {{ description }}
        </p>
        <fieldset class="flex flex-col gap-y-4">
            <div
                v-for="(option, index) in options"
                :key="option.value"
                class="flex items-center justify-start cursor-pointer"
                :class="setOptionLengthStyle(option?.isOther)"
            >
                <GgmsCheckBox
                    v-if="!option?.isOther"
                    v-model="model[index]"
                    :id="name"
                    :label="option.displayName"
                    :disabled="disabled"
                />
                <div v-else class="flex gap-x-5 w-full">
                    <GgmsCheckBox v-model="model[index]" :id="name" label="Other:" :disabled="disabled" />
                    <GgmsInput
                        v-model="otherValue"
                        :small="true"
                        :disabled="disabled"
                        class="w-full"
                        @input="changeOtherValue()"
                    />
                </div>
            </div>
        </fieldset>
        <p v-if="localErrorMessage" class="mt-2 text-sm text-red-600">{{ localErrorMessage }}</p>
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue"
import GgmsCheckBox from "@/components/GgmsCheckBox.vue"
import GgmsInput from "@/components/GgmsInput.vue"

interface OptionType {
    displayName: string
    value: any
    isOther?: boolean
    otherValue?: string
}

interface Props {
    modelValue: boolean[]
    label?: string
    description?: string
    required?: boolean
    inline?: boolean
    disabled?: boolean
    options: OptionType[]
    name?: string
    invalid?: boolean
    errorMessage?: string
}

const props = withDefaults(defineProps<Props>(), {
    modelValue: [] as boolean[],
    label: "",
    required: false,
    disabled: false,
    options: [] as OptionType[],
    inline: false,
    name: "",
    invalid: false,
})
const emit = defineEmits(["update:modelValue", "update:options"])
const localInvalid = ref(props.invalid)
const localErrorMessage = ref(props.errorMessage)
const otherValue = ref(props.options.at(-1)?.otherValue || "")

const starStyle = computed(() => [localInvalid.value ? "text-red-600" : "text-gray-700", "pl-1"])
const getRequiredStyleInline = computed(() => (props.inline ? "gap-2" : ""))

const model = computed({
    get() {
        return props.modelValue
    },
    set(value: boolean[]) {
        emit("update:modelValue", value)
    },
})

function getWrapperStyleInline() {
    return props.inline ? "flex items-center gap-4" : ""
}

function getLabelStyleInline() {
    return props.inline ? "w-2/5 whitespace-nowrap pr-2 lg:pr-0" : "mb-4"
}

function setOptionLengthStyle(isOther: boolean | undefined) {
    return isOther ? "w-full" : "w-fit"
}

function changeOtherValue() {
    const index = props.options.length - 1
    model.value[index] = !!otherValue.value?.length
    emit("update:options", otherValue.value)
}

function disableValidationError() {
    localErrorMessage.value = ""
    localInvalid.value = false
}

onMounted(() => {
    if (!props.modelValue.length) {
        model.value = props.options.map(() => false)
    }
})

watch(
    () => props.invalid,
    () => (localInvalid.value = props.invalid)
)

watch(
    () => props.errorMessage,
    () => {
        localErrorMessage.value = props.errorMessage
        localInvalid.value = props.invalid
    }
)

watch(
    () => model.value,
    () => {
        const trueResponses = model.value.filter((response) => response)
        if (trueResponses.length === 0 && props.invalid) {
            localErrorMessage.value = props.errorMessage
            localInvalid.value = props.invalid
        } else {
            disableValidationError()
        }
    },
    { deep: true }
)
</script>

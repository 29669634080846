<template>
    <div class="border-gray-300 border rounded-md text-sm px-4 py-2 h-[344px] overflow-y-auto">
        <div class="w-full border-b pb-2">
            <span class="font-medium">Subject:</span>
            <span class="ml-2.5 font-normal text-base"> {{ modelValue.subject }}</span>
        </div>
        <div v-html="templateBody" class="pt-2"></div>
    </div>
</template>
<script setup lang="ts">
import { onMounted, ref } from "vue"
import { Template } from "@/shared/models/template"
import { useConfigStore } from "@/stores/config"
import { renderTemplate } from "@/shared/utils/helpers"
import { Contact } from "@/shared/models/contact"
import { Agency } from "@/shared/models/agency"
import { Agent } from "@/shared/models/agent"
import { useMessageThemeStore } from "@/stores/message-theme"
import { useContactsStore } from "@/stores/contacts"

interface Props {
    modelValue: Template
}
const props = defineProps<Props>()
const configStore = useConfigStore()
const messageThemeStore = useMessageThemeStore()
const contactStore = useContactsStore()

const templateBody = ref(props.modelValue.body)

function getBodyOfMessageTheme(channel: string) {
    return messageThemeStore.messageThemes.find((theme) => theme.channel === channel)?.body
}

function getTheme() {
    if (props.modelValue.templateType === "email") {
        return getBodyOfMessageTheme("RichEmail")
    }
    return getBodyOfMessageTheme("TextMessage")
}

function addSignatureToTemplateBody() {
    const models = {
        contact: contactStore.contact as Contact,
        agency: {} as Agency,
        agent: {} as Agent,
    }
    const theme = getTheme() || ""
    const signature = configStore?.signature?.body || ""

    templateBody.value = renderTemplate(props.modelValue.body, configStore.prototypes, models, theme, signature)
    if (props.modelValue.templateType === "sms") {
        // remove html tags and replace \n with <br>
        templateBody.value = templateBody.value.replace(/(<([^>]+)>)/gi, "").replace(/\n/g, "<br>")
    }
}

onMounted(() => {
    addSignatureToTemplateBody()
})
</script>

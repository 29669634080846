<template>
    <Sidebar
        v-model:visible="gridCollectionStore.isFilterSidebarVisible"
        position="right"
        :showCloseIcon="false"
        autoZIndex
    >
        <template #header>
            <div class="flex items-center justify-between w-full">
                <p class="text-white text-lg font-medium">Filters</p>
                <XIcon class="h-6 w-6 text-white cursor-pointer" @click="closeDialog" />
            </div>
        </template>
        <AttributeList
            v-if="isCurrentFilterStep('attributeList')"
            @onAttributeSelect="selectAttribute"
            @cancel="cancel"
        />
        <FilterRule
            v-if="isCurrentFilterStep('filterRule')"
            :filter="selectedFilter"
            :isUpdated="isEditFilter"
            @applyFilter="applyFilter"
            @cancel="cancelFilter"
        />
        <FilterList
            v-if="isCurrentFilterStep('filterList')"
            @addNewFilter="openAttributeList"
            @editFilter="editFilter"
            @clearFilters="clearFilters"
        />
    </Sidebar>
</template>

<script lang="ts" setup>
import { ref, watch } from "vue"
import { useRoute } from "vue-router"
import AttributeList from "@/components/filters/AttributeList.vue"
import FilterRule from "@/components/filters/FilterRule.vue"
import FilterList from "@/components/filters/FilterList.vue"
import { XIcon } from "@heroicons/vue/outline"
import { useContactsStore } from "@/stores/contacts"
import { useTaskStore } from "@/stores/task"
import { useAgentsStore } from "@/stores/agents"
import { useFormStore } from "@/stores/form"
import { useInboxStore } from "@/stores/inbox"
import { useTagStore } from "@/stores/tag"
import { useTemplateStore } from "@/stores/template"
import { useGridCollectionStore } from "@/stores/grid-collection"
import { useJobsStore } from "@/stores/jobs"
import { useOriginStore } from "@/stores/origin"
import { useEventLogStore } from "@/stores/event-log"
import { useWorkflowStore } from "@/stores/workflow"
import { useListingStore } from "@/stores/listings"
import { useInvoiceStore } from "@/stores/invoice"
import { useContactListingStore } from "@/stores/contact-listings"
import { Attribute } from "@/shared/models/attributes"
import { Filter } from "@/shared/models/filter"

const route = useRoute()

const gridCollectionStore = useGridCollectionStore()
const contactStore = useContactsStore()
const taskStore = useTaskStore()
const agentsStore = useAgentsStore()
const formStore = useFormStore()
const inboxStore = useInboxStore()
const jobStore = useJobsStore()
const templateStore = useTemplateStore()
const tagStore = useTagStore()
const originStore = useOriginStore()
const eventLogStore = useEventLogStore()
const workflowStore = useWorkflowStore()
const listingStore = useListingStore()
const contactListingStore = useContactListingStore()
const invoiceStore = useInvoiceStore()

const selectedFilter = ref<Filter>({
    field: "",
    type: "",
    rule: "",
    value: "",
    displayName: "",
})

const isEditFilter = ref(false)
const selectedFilterIndex = ref(0)

function closeDialog() {
    gridCollectionStore.isFilterSidebarVisible = false
}

function selectAttribute(attribute: Attribute) {
    selectedFilter.value.field = attribute.name
    selectedFilter.value.type = attribute.type
    selectedFilter.value.displayName = attribute.displayName
    setFilterSidebarCurrentStep(attribute.name)
    isEditFilter.value = false
}

function setFilterSidebarCurrentStep(attributeName: string) {
    gridCollectionStore.collectionTypeHistory.push(attributeName)

    if (attributeName === "agent") {
        gridCollectionStore.getPrototypesByPage("agents")
        gridCollectionStore.setFilterDefaultCurrentStep()
        return
    }
    if (attributeName === "fullName" && gridCollectionStore.collectionType !== "agents") {
        selectedFilter.value.field = "agent.fullName"
        selectedFilter.value.displayName = "Agent's Name"
    }
    gridCollectionStore.filterSidebarCurrentStep = "filterRule"
}

function isCurrentFilterStep(step: string) {
    return gridCollectionStore.filterSidebarCurrentStep === step
}

function resetSelectedFilter() {
    selectedFilter.value = {
        field: "",
        type: "",
        rule: "",
        value: "",
    }
}

function isFilterEmpty(filter: Filter) {
    return (
        (filter.value === "" ||
            (Array.isArray(filter.value) && filter.value.length === 0) ||
            (Array.isArray(filter.value) && filter.value.every((val) => val === ""))) &&
        filter.rule !== "isEmpty" &&
        filter.rule !== "isNotEmpty"
    )
}

async function applyFilter(filter: Filter) {
    if (["body"].includes(filter.field)) {
        filter.field = `messages.${filter.field}`
    }

    isEditFilter.value
        ? gridCollectionStore.editFilterGroup(filter, selectedFilterIndex.value)
        : gridCollectionStore.addFilterToGroup(filter)
    openFiltersList()

    if (isFilterEmpty(filter)) {
        // Filter out non-empty filters
        gridCollectionStore.filtersGroups.forEach((filterGroup) => {
            filterGroup.filters = filterGroup.filters.filter((f) => !isFilterEmpty(f))
        })

        // Filter out filter groups with no filters
        gridCollectionStore.filtersGroups = gridCollectionStore.filtersGroups.filter(
            (filterGroup) => filterGroup.filters.length > 0
        )

        if (gridCollectionStore.filtersGroups.length === 0) {
            openAttributeList()
        }
    }

    if (gridCollectionStore.collectionType === "contacts") {
        await contactStore.updateContactsGrid()
        resetSelectedFilter()
        return
    }
    if (gridCollectionStore.collectionType === "tasks") {
        gridCollectionStore.getPrototypesByPage("tasks")
        await taskStore.updateTasksGrid()
        resetSelectedFilter()
        return
    }
    if (gridCollectionStore.collectionType === "agents") {
        await agentsStore.updateAgentsGrid()
        resetSelectedFilter()
        return
    }
    if (gridCollectionStore.collectionType === "forms") {
        await formStore.updateFormsGrid()
        resetSelectedFilter()
        return
    }
    if (gridCollectionStore.collectionType === "inbox") {
        await inboxStore.getInbox()
        resetSelectedFilter()
        return
    }
    if (gridCollectionStore.collectionType === "templates") {
        await templateStore.updateTemplatesGrid()
        resetSelectedFilter()
        return
    }
    if (gridCollectionStore.collectionType === "jobs") {
        await jobStore.updateJobsGrid()
        resetSelectedFilter()
        return
    }
    if (gridCollectionStore.collectionType === "origins") {
        await originStore.updateOriginGrid()
        resetSelectedFilter()
        return
    }
    if (gridCollectionStore.collectionType === "tag") {
        await tagStore.updateTagGrid()
        resetSelectedFilter()
        return
    }
    if (gridCollectionStore.collectionType === "logs") {
        await eventLogStore.updateEventLogsGrid()
        resetSelectedFilter()
        return
    }
    if (gridCollectionStore.collectionType === "workflows") {
        await workflowStore.updateWorkflowsGrid()
        resetSelectedFilter()
        return
    }
    if (gridCollectionStore.collectionType === "listings") {
        await listingStore.updateListingGrid()
        resetSelectedFilter()
        return
    }
    if (gridCollectionStore.collectionType === "contact-listings") {
        await contactListingStore.updateListingGrid()
        resetSelectedFilter()
        return
    }
    if (gridCollectionStore.collectionType === "invoices") {
        await invoiceStore.updateInvoiceGrid()
        resetSelectedFilter()
        return
    }
}

function cancel() {
    // we use this function to go back to the previous attribute list (for example: from 'agent' to 'task')
    gridCollectionStore.collectionTypeHistory.pop()
    setPrototypesByCollectionType()
}

function cancelFilter() {
    gridCollectionStore.collectionTypeHistory.pop()
    resetSelectedFilter()
    if (!gridCollectionStore.filtersGroups?.length) {
        openAttributeList()
        return
    }
    openFiltersList()
}

function openFiltersList() {
    gridCollectionStore.filterSidebarCurrentStep = "filterList"
}

function openAttributeList() {
    gridCollectionStore.filterSidebarCurrentStep = "attributeList"
}

function editFilter(filter: Filter, filterIndex: number) {
    selectedFilter.value = {
        field: filter.field,
        type: filter.type,
        rule: filter.rule,
        value: filter.value,
        displayName: filter.displayName,
    }
    isEditFilter.value = true
    gridCollectionStore.filterSidebarCurrentStep = "filterRule"
    selectedFilterIndex.value = filterIndex
}

async function clearFilters() {
    gridCollectionStore.clearFilters()
    setCollectionTypeHistory()
    gridCollectionStore.filterSidebarCurrentStep = "attributeList"

    if (gridCollectionStore.collectionType === "contacts") {
        await contactStore.updateContactsGrid()
        return
    }
    if (gridCollectionStore.collectionType === "tasks") {
        await taskStore.updateTasksGrid()
        return
    }
    if (gridCollectionStore.collectionType === "agents") {
        await agentsStore.updateAgentsGrid()
        return
    }
    if (gridCollectionStore.collectionType === "forms") {
        await formStore.updateFormsGrid()
        return
    }
    if (gridCollectionStore.collectionType === "templates") {
        await templateStore.updateTemplatesGrid()
        return
    }
    if (gridCollectionStore.collectionType === "inbox") {
        await inboxStore.getInbox()
        return
    }
    if (gridCollectionStore.collectionType === "jobs") {
        await jobStore.updateJobsGrid()
        return
    }
    if (gridCollectionStore.collectionType === "origins") {
        await originStore.updateOriginGrid()
        return
    }
    if (gridCollectionStore.collectionType === "tag") {
        await tagStore.updateTagGrid()
        return
    }
    if (gridCollectionStore.collectionType === "logs") {
        await eventLogStore.updateEventLogsGrid()
        return
    }
    if (gridCollectionStore.collectionType === "workflows") {
        await workflowStore.updateWorkflowsGrid()
        return
    }
    if (gridCollectionStore.collectionType === "listings") {
        await listingStore.updateListingGrid()
        return
    }
    if (gridCollectionStore.collectionType === "invoices") {
        await invoiceStore.updateInvoiceGrid()
        return
    }
}

function setCollectionTypeHistory() {
    const pageName = route.name as string
    if (pageName === "contacts") {
        gridCollectionStore.collectionTypeHistory = ["contacts"]
        return
    }
    if (pageName === "team") {
        gridCollectionStore.collectionTypeHistory = ["agents"]
        return
    }
    gridCollectionStore.collectionTypeHistory = [pageName]
}

function setPrototypesByCollectionType() {
    const lastCollectionType = gridCollectionStore.collectionTypeHistory.at(-1) as string
    gridCollectionStore.getPrototypesByPage(lastCollectionType)
}

//we cannot use computed getter setter cause we control the opening of the sidebar from other components
watch(
    () => gridCollectionStore.isFilterSidebarVisible,
    async (value) => {
        if (!value) {
            cancelFilter()
            return
        }

        if (gridCollectionStore.filtersGroups?.length) {
            gridCollectionStore.filterSidebarCurrentStep = "filterList"
        }

        setCollectionTypeHistory()
        setPrototypesByCollectionType()

        // remove 's' letter from the end of the collectionType if it ends with 's'
        const collectionType = gridCollectionStore.collectionType.replace(/s$/, "")
        const isTagsIncludes = gridCollectionStore.prototypes?.[0]?.attributes?.some(
            (prototype) => prototype.name === "tags"
        )
        const isOriginsIncludes = gridCollectionStore.prototypes?.[0]?.attributes?.some(
            (prototype) => prototype.name === "origins"
        )

        const promises = [
            ...(isTagsIncludes
                ? [
                      (tagStore.tableState.typeName =
                          gridCollectionStore.collectionType === "templates" ? "messaging-template" : collectionType),
                      tagStore.getTags(),
                  ]
                : []),
            ...(isOriginsIncludes ? [originStore.getAllOrigins()] : []),
        ]

        await Promise.all(promises)
    }
)
</script>

<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        :class="props.class"
        :fill="props.fill"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_8440_4666)">
            <path
                d="M3 19V3C3 1.89543 3.89543 1 5 1H15C16.1046 1 17 1.89543 17 3V8.99998M3 19L1 19M3 19H8M7 4.99998H8M7 8.99998H8M12 4.99998H13M12 8.99998H13M8 19V14C8 13.4477 8.44772 13 9 13H11C11.5523 13 12 13.4477 12 14V19H8Z"
                stroke="#9CA3AF"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M18 13L16.5182 15.2227C16.2967 15.5549 16.5349 16 16.9343 16H23"
                stroke="#9CA3AF"
                stroke-width="2.5"
                stroke-linecap="round"
            />
            <path
                d="M21 23L22.4818 20.7773C22.7033 20.4451 22.4651 20 22.0657 20L16 20"
                stroke="#9CA3AF"
                stroke-width="2.5"
                stroke-linecap="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_8440_4666">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script lang="ts" setup>
import { withDefaults, defineProps } from "vue"

interface Props {
    class?: string
    fill?: string
}

const props = withDefaults(defineProps<Props>(), {
    class: "",
    fill: "none",
})
</script>

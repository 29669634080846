<template>
    <div class="flex flex-col gap-4">
        <div class="flex items-center gap-4">
            <ArrowLeftIcon
                v-if="canGoBack"
                class="h-6 w-6 text-primary-color-700 cursor-pointer shrink-0"
                @click.stop="cancelFilter"
            />
            <GgmsInput v-model="searchQuery" :iconStart="SearchIcon" placeholder="Search" class="w-full" />
        </div>
        <div
            :class="{
                'border border-gray-200 rounded-lg shadow-sm bg-white':
                    gridCollectionStore.collectionType !== 'listings',
            }"
        >
            <!-- If collectionType is 'listings', display attributes in collapsible groups -->
            <div v-if="gridCollectionStore.collectionType === 'listings'">
                <div v-for="group in propertyLayoutGroups" :key="group.name">
                    <div class="flex gap-x-2 pt-3 cursor-pointer" @click="toggleGroup(group.name)">
                        <span class="text-base leading-6 font-semibold text-gray-700">{{ group.name }}</span>
                        <ArrowDown v-if="openGroups[group.name]" class="fill-gray-700"></ArrowDown>
                        <ArrowDown v-else class="rotate-180 fill-gray-700"></ArrowDown>
                    </div>
                    <transition name="fade">
                        <div v-if="openGroups[group.name]" class="flex flex-col pt-3">
                            <div
                                v-for="attribute in getFilteredAttributes(group.properties)"
                                :key="attribute._id"
                                class="p-2.5 text-gray-900 cursor-pointer hover:bg-gray-100 rounded-lg"
                                @click.stop="selectAttribute(attribute)"
                            >
                                <span class="text-sm leading-5 font-medium text-gray-500">{{
                                    attribute.displayName
                                }}</span>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>

            <!-- Else display attributes in their original format -->
            <div v-else>
                <div v-for="prototype in prototypes" :key="prototype.typeName">
                    <div class="flex flex-col gap-1 px-2 pt-2">
                        <div
                            v-if="getFilteredAttributes(prototype.attributes).length === 0 && searchQuery"
                            class="p-2.5 text-gray-900"
                        >
                            No results
                        </div>
                        <div
                            v-else
                            v-for="attribute in getFilteredAttributes(prototype.attributes)"
                            :key="attribute._id"
                            class="p-2.5 text-gray-900 cursor-pointer hover:bg-gray-100 rounded-lg"
                            @click.stop="selectAttribute(attribute)"
                        >
                            {{ attribute.displayName }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from "vue"
import GgmsInput from "@/components/GgmsInput.vue"
import { SearchIcon, ArrowLeftIcon } from "@heroicons/vue/outline"
import { Attribute } from "@/shared/models/attributes"
import { useGridCollectionStore } from "@/stores/grid-collection"
import { useConfigStore } from "@/stores/config"
import { PropertyGroup } from "@/shared/models/property-group-layout"
import ArrowDown from "@/components/icons/ArrowDown.vue"

const gridCollectionStore = useGridCollectionStore()
const configStore = useConfigStore()

const emit = defineEmits(["onAttributeSelect", "cancel"])

const searchQuery = ref("")
const prototypes = computed(() => gridCollectionStore.prototypes)
const canGoBack = computed(() => gridCollectionStore.collectionTypeHistory.length > 1)
const propertyLayoutGroups = ref<PropertyGroup[]>([])

// Initialize all groups as open by default
const openGroups = ref<Record<string, boolean>>({})

function getFilteredAttributes(attributes: Attribute[]) {
    return attributes.filter((attribute) =>
        attribute.displayName.toLowerCase().includes(searchQuery.value.toLowerCase())
    )
}

function selectAttribute(attribute: Attribute) {
    emit("onAttributeSelect", attribute)
}

function cancelFilter() {
    emit("cancel")
}

// Toggle the group open/closed
function toggleGroup(groupName: string) {
    openGroups.value[groupName] = !openGroups.value[groupName]
}

onMounted(() => {
    propertyLayoutGroups.value = configStore.crmPropertyGroups

    // Open all groups by default
    propertyLayoutGroups.value.forEach((group: PropertyGroup) => {
        openGroups.value[group.name] = true
    })
})
</script>

import { AuthUserRole } from "./user"

export enum PermissionType {
    READ = 0b100,
    WRITE = 0b110,
    ADMIN = 0b111,
}

export type ResourceTypeName =
    | "agency"
    | "agent"
    | "contact"
    | "tags"
    | "task"
    | "forms"
    | "collection"
    | "publisher"
    | "apiKey"
    | "call"
    | "emailMessaging"
    | "smsMessaging"
    | "themes"
    | "templates"
    | "note"
    | "*"

export interface Role {
    name: AuthUserRole
    permissions?: Permission[]
}

export interface Permission {
    resource: ResourceTypeName
    type: PermissionType
}

export interface ACL {
    _id?: string
    agentId?: string
    listId?: string
    agentIds?: string[]
    permission?: PermissionType | string
    displayName?: string
    type?: string
    mixed?: boolean
    isDisabled?: boolean
    profilePicture?: string
}

<template>
    <div class="sticky top-0 z-40 flex items-center justify-between py-3.5 px-4 bg-primary-color-700">
        <p class="text-white">{{ callDuration }}</p>
        <p class="text-white">{{ stateTitle }}: {{ contactFullName }}</p>
        <div class="flex gap-x-4 items-center">
            <UserIcon class="h-6 w-6 text-white cursor-pointer" @click="openContactPage"></UserIcon>
            <MutedMicIcon
                v-if="phoneCallStore.isMicMuted"
                class="h-6 w-6 text-white stroke-white cursor-pointer"
                @click="toggleMute"
            />
            <MicrophoneIcon v-else class="h-6 w-6 text-white cursor-pointer" @click="toggleMute" />
            <LoginIcon class="h-6 w-6 text-white cursor-pointer" @click="openSidebar"></LoginIcon>
            <GgmsButton
                :iconStart="PhoneIcon"
                styleType="danger"
                :iconClasses="['rotate-135 text-white']"
                @click="closeCall"
                >Hang Up</GgmsButton
            >
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed } from "vue"
import { useRouter } from "vue-router"
import GgmsButton from "@/components/GgmsButton.vue"
import MutedMicIcon from "@/components/icons/MutedMicIcon.vue"
import { PhoneIcon, UserIcon, MicrophoneIcon } from "@heroicons/vue/outline"
import { LoginIcon } from "@heroicons/vue/solid"
import { usePhoneCallStore } from "@/stores/phone-call"
import { formatNumberToMinutesAndSeconds, getFullName } from "@/shared/utils/helpers"

const router = useRouter()
const phoneCallStore = usePhoneCallStore()

const emit = defineEmits(["onCloseCall", "onOpenSidebar"])

const callDuration = computed(() => formatNumberToMinutesAndSeconds(phoneCallStore.callDuration))

const stateTitle = computed(() => {
    const titles = {
        calling: "Calling",
        ongoing: "Connected",
        finished: "Ended",
    }
    return titles[phoneCallStore.phoneCallState]
})

const contactFullName = computed(() =>
    getFullName(phoneCallStore.selectedContact.firstName, phoneCallStore.selectedContact.lastName)
)

function closeCall() {
    emit("onCloseCall")
}

function openSidebar() {
    emit("onOpenSidebar")
}

function toggleMute() {
    phoneCallStore.toggleMute()
}

function openContactPage() {
    router.push(`/contacts/${phoneCallStore.currentPhoneCall?.contacts?.[0]?._id}`)
}
</script>

<style scoped></style>

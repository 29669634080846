<template>
    <div class="grid grid-cols-3 items-center bg-white shadow pl-3 pr-5 py-5 w-full">
        <GgmsLogo class="justify-self-start" logoClass="w-16" />
        <h1 class="font-medium text-gray-900 capitalize justify-self-center text-center">{{ pageName }}</h1>
        <GgmsButton
            styleType="simple"
            :classes="['py-0 px-0 h-[20px]']"
            class="justify-self-end"
            @click="toggleSidebar(true)"
        >
            <MenuIcon class="h-6 w-6" />
        </GgmsButton>

        <TransitionRoot as="template" :show="isSidebarOpen">
            <Dialog as="div" class="fixed inset-0 flex z-40 md:hidden" @close="toggleSidebar(false)">
                <TransitionChild
                    as="template"
                    enter="transition ease-in-out duration-300 transform"
                    enter-from="translate-x-full"
                    enter-to="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leave-from="translate-x-0"
                    leave-to="translate-x-full"
                >
                    <div class="relative flex-1 flex flex-col w-full pt-5 pb-4 bg-white">
                        <GgmsButton
                            styleType="simple"
                            :classes="['py-0 px-0 h-[20px]']"
                            class="self-end px-4 pb-5"
                            @click="toggleSidebar(false)"
                        >
                            <XIcon class="h-6 w-6" />
                        </GgmsButton>

                        <MenuActionItems
                            @openPhoneSidebar="openPhoneSidebar"
                            @openAddContactModal="openAddContactModal"
                            @openNotificationSidebar="openNotificationSidebar"
                        />

                        <GgmsNavigation :items="navigation" />
                    </div>
                </TransitionChild>
            </Dialog>
        </TransitionRoot>
    </div>
</template>

<script lang="ts" setup>
import { computed } from "vue"
import { useRoute } from "vue-router"
import GgmsLogo from "@/components/GgmsLogo.vue"
import GgmsButton from "@/components/GgmsButton.vue"
import GgmsNavigation from "@/components/GgmsNavigation.vue"
import MenuActionItems from "@/components/MenuActionItems.vue"
import { TransitionChild, TransitionRoot, Dialog } from "@headlessui/vue"
import { MenuIcon, XIcon } from "@heroicons/vue/outline"
import { useNavigationSidebarStore } from "@/stores/navigation-sidebar"
import { NavigationItem } from "@/shared/models/navigation"

const route = useRoute()

const navigationSidebarStore = useNavigationSidebarStore()

interface Props {
    navigation: NavigationItem[]
}

const props = defineProps<Props>()

const emit = defineEmits(["openPhoneSidebar", "openAddContactModal", "openNotificationSidebar"])

const isSidebarOpen = computed(() => navigationSidebarStore.isSidebarOpen)

const pageName = computed(() => {
    // find the page name from the navigation items or from the navigation children items
    let navigationItemName = ""

    for (const item of props.navigation) {
        if (item.path === route.path) {
            navigationItemName = item.name
            break
        }

        const navigationChildItem = item.children?.find((childItem) => childItem.path === route.path)
        if (navigationChildItem) {
            navigationItemName = navigationChildItem.name
            break
        }
    }

    // we have to check separately for the company settings page because it doesn't have a navigation item
    if (navigationItemName === "" && route.path === "/company-settings") {
        navigationItemName = "Company Settings"
    }

    return navigationItemName
})

function toggleSidebar(value: boolean) {
    navigationSidebarStore.toggleSidebar(value)
}

function openPhoneSidebar() {
    emit("openPhoneSidebar")
}

function openAddContactModal() {
    emit("openAddContactModal")
}

function openNotificationSidebar() {
    emit("openNotificationSidebar")
}
</script>

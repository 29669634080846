<template>
    <div class="flex flex-col gap-y-2">
        <p class="text-lg leading-7 font-medium text-black">When following property value/s change:</p>
        <div
            v-for="option in attributeStore.getAttributesForPropValueChange"
            :key="option._id"
            class="flex flex-col"
            tabindex="0"
            @click="onSelect(option.name, option.displayName)"
        >
            <GgmsCheckBox :checked="isValueChecked(option.name)" :classes="['mt-1']" :centered="false">
                <div class="ml-3 text-sm leading-5 font-normal text-gray-900">
                    {{ option.displayName }}
                </div>
            </GgmsCheckBox>
        </div>
    </div>
</template>
<script setup lang="ts">
import { onMounted, watch } from "vue"
import { useAttributesStore } from "@/stores/attributes"
import { useWorkflowStore } from "@/stores/workflow"
import GgmsCheckBox from "@/components/GgmsCheckBox.vue"

const workflowStore = useWorkflowStore()
const attributeStore = useAttributesStore()

function isValueChecked(value: string) {
    return !!workflowStore.selectedData.find((el) => el.name === value)
}

function onSelect(value: string, displayName: string) {
    if (isValueChecked(value)) {
        const index = workflowStore.selectedData.findIndex((el) => el.name === value)
        workflowStore.selectedData.splice(index, 1)
        return
    }
    workflowStore.selectedData.push({ name: value, label: displayName })
}

onMounted(async () => {
    await attributeStore.getAttributes("contact")
})

watch(
    () => workflowStore.selectedData,
    () => {
        workflowStore.areButtonDisabled = !workflowStore.selectedData.length
    },
    { deep: true }
)
</script>

<template>
    <div :class="['relative', inlineStyle]">
        <Editor
            v-model="model"
            :key="editorModules.imageResize?.displaySize?.toString()"
            editor-style="height: 201px"
            :placeholder="placeholder"
            :modules="editorModules"
            :class="[errorStyle, toolbarStyle]"
            :readonly="isReadonlyEditor"
            @load="editorLoad"
            @click="disableValidationError"
            @selection-change="onSelectionChange"
        >
            <template #toolbar>
                <span class="flex justify-between" v-if="isPreview">
                    <p class="text-sm font-medium text-gray-700">Preview</p>
                    <div class="flex self-center cursor-pointer" @click="setPreview()">
                        <PencilIcon class="h-5 w-5 text-primary-color-600" aria-hidden="true" />
                    </div>
                </span>
                <span class="flex justify-between items-center h-6" v-else-if="!isHiddenToolbar">
                    <div>
                        <span class="ql-formats">
                            <button v-for="format in textFormats" :key="format" :class="`ql-${format}`"></button>
                        </span>
                        <span class="ql-formats">
                            <button v-for="format in mediaFormats" :key="format" :class="`ql-${format}`"></button>
                        </span>
                        <span class="ql-formats">
                            <button class="ql-list" value="ordered"></button>
                            <button class="ql-list" value="bullet"></button>
                            <select class="ql-align">
                                <option selected></option>
                                <option value="center"></option>
                                <option value="right"></option>
                                <option value="justify"></option>
                            </select>
                        </span>
                    </div>
                    <div v-if="props.errorMessage" class="flex self-center cursor-pointer">
                        <ExclamationCircleIcon class="h-5 w-5 text-red-600" aria-hidden="true" />
                    </div>
                </span>
                <span v-else></span>
            </template>
        </Editor>
    </div>
    <p v-if="errorMessage" class="self-start text-sm text-red-600 w-full">{{ errorMessage }}</p>
</template>

<script setup lang="ts">
import Editor, { EditorSelectionChangeEvent } from "primevue/editor"
import { computed, defineProps, defineEmits, ref, watch, onMounted } from "vue"
import Quill from "quill"
import { ExclamationCircleIcon } from "@heroicons/vue/solid"
import { PencilIcon } from "@heroicons/vue/outline"
import { useAgencyThemeStore } from "@/stores/agency-theme"

interface EditorModuleTypes {
    "emoji-toolbar": boolean
    imageResize?: {
        displaySize: boolean
    }
}

const agencyTheme = useAgencyThemeStore()

const props = defineProps({
    modelValue: {
        type: String,
        default: "",
    },
    placeholder: {
        type: String,
        default: "",
    },
    errorMessage: {
        type: String,
        default: "",
    },
    readonly: {
        type: Boolean,
        default: false,
    },
    isHiddenToolbar: {
        type: Boolean,
        default: false,
    },
    inline: {
        type: Boolean,
        default: false,
    },
    isPreviewVisible: {
        type: Boolean,
        default: false,
    },
})

const emit = defineEmits(["update:modelValue", "load", "disableValidationError"])

const quill = ref()
const textFormats = ["bold", "italic", "underline", "strike"]
const mediaFormats = ["link", "image", "video"]
const isContentFocused = ref(!props.isPreviewVisible)
const linkColor = computed(() => agencyTheme?.agencyTheme?.linkColor || "#0090BE")

const model = computed({
    get: () => props.modelValue,
    set: (value) => {
        if (isContentFocused.value) {
            value = setLinkColor(value)
            emit("update:modelValue", value)
        }
    },
})
const errorStyle = computed(() => (props.errorMessage.length ? "error-style" : ""))
const toolbarStyle = computed(() => (props.isHiddenToolbar ? "hideToolbar" : ""))
const inlineStyle = computed(() => (props.inline ? "w-[512px]" : "w-full"))
const isPreview = ref(props.isPreviewVisible)
const isReadonlyEditor = ref(props.readonly)

const editorModules = ref<EditorModuleTypes>({
    "emoji-toolbar": isPreview.value,
    imageResize: { displaySize: true },
})

function setLinkColor(value: string) {
    if (value.includes("<a")) {
        const regex = /<a.*?href="(.*?)".*?>(.*?)<\/a>/g
        const matches = value.matchAll(regex)
        for (const match of matches) {
            const link = match[0].split("<a")[1]
            const linkColorStyle = `style="color: ${linkColor.value}"`
            value = value.replace(link, ` ${linkColorStyle} ${link}`)
        }
    }
    return value
}

function editorLoad(editor: EditorSelectionChangeEvent) {
    quill.value = (editor as unknown as { instance: Quill }).instance
    emit("load", quill.value)
}

function setPreview(value?: boolean) {
    isPreview.value = value || !isPreview.value
    isReadonlyEditor.value = value || !isReadonlyEditor.value
    setEditorImageResize()
}

function disableValidationError() {
    emit("disableValidationError")
}

function onSelectionChange(editor: EditorSelectionChangeEvent) {
    if (isPreview.value) {
        return
    }
    isContentFocused.value = editor.range !== null
    if (isContentFocused.value) {
        emit("update:modelValue", editor.htmlValue)
    }
}

function setEditorImageResize() {
    if (isPreview.value) {
        delete editorModules.value.imageResize
        isContentFocused.value = false
    } else {
        editorModules.value.imageResize = { displaySize: true }
    }
}

onMounted(() => {
    setEditorImageResize()
})

watch(
    () => props.isPreviewVisible,
    (value) => {
        setPreview(value)
    }
)
</script>

<style scoped:deep>
.p-editor-toolbar {
    @apply !bg-white;
}

.error-style {
    @apply border border-red-300 rounded-md;
}

.hideToolbar .p-editor-toolbar.ql-snow {
    @apply hidden;
}

.hideToolbar .ql-toolbar.ql-snow + .ql-container.ql-snow {
    @apply rounded-t-md;
}

.hideToolbar .p-editor-content .ql-editor {
    @apply border-t rounded-t-md;
}

.ql-editor p {
    @apply text-base font-normal;
}

.ql-editor.ql-blank::before {
    font-style: normal !important;
    font-size: 1rem;
    color: #9ca3af !important;
}

.ql-editor p > a {
    color: v-bind(linkColor);
}
</style>

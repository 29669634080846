<template>
    <div :class="[inline ? '' : 'flex-col', 'flex']">
        <div class="flex">
            <label :for="id" :class="[disabled ? 'opacity-40' : '', 'block mb-1 text-sm font-medium text-gray-700']">{{
                label
            }}</label>
            <span v-if="required" :class="starStyle">*</span>
        </div>

        <div class="relative w-full">
            <flat-pickr
                v-model="inputValue"
                :config="config"
                :id="id"
                :class="inputStyle"
                @input="disableValidationError"
            ></flat-pickr>
            <!-- Overlay for readonly as flatpickr doesn't support it by default -->
            <div v-if="disabled" class="absolute top-0 left-0 w-full h-full bg-white bg-opacity-60"></div>
            <p v-if="localInvalid" class="mt-2 text-sm text-red-600">{{ localErrorMessage }}</p>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, computed, watch } from "vue"
import flatPickr from "vue-flatpickr-component"
import "flatpickr/dist/flatpickr.css"
import { formatDateToIso } from "@/shared/utils/helpers"

interface Props {
    position?: string
    modelValue: string | Date | undefined
    showTime?: boolean
    showDate?: boolean
    label?: string
    id?: string
    disabled?: boolean
    inline?: boolean
    minDate?: string
    required?: boolean
    errorMessage?: string
    invalid?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    inline: false,
})
const emit = defineEmits(["update:modelValue"])

let internalValue = ""

const config = ref({
    position: props.position,
    enableTime: props.showTime,
    noCalendar: !props.showDate,
    minDate: props.minDate,
})

const inputValue = computed({
    get: () => {
        if (props.modelValue !== internalValue) {
            internalValue = String(props.modelValue) ?? ""
            if (props.modelValue instanceof Date && !isNaN(props.modelValue.getTime())) {
                const date = new Date(props.modelValue)
                const newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
                return newDate.toISOString().slice(0, -1)
            } else if (typeof props.modelValue === "string") {
                return formatDateToIso(props.modelValue).slice(0, -1)
            }
        }
        return internalValue
    },
    set: (value: string | Date) => {
        internalValue = String(value)
        emit("update:modelValue", value)
    },
})

const localErrorMessage = ref(props.errorMessage)
const localInvalid = ref(props.invalid)

const inputStyle = computed(() => [
    localInvalid.value && !inputValue.value
        ? "pr-10 border-red-300 placeholder-red-300 focus:ring-red-500 focus:border-red-500"
        : "placeholder-gray-400 focus:ring-primary-color-500 focus:border-primary-color-500 border-gray-300",
    "appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none text-base",
])

const starStyle = computed(() => [localInvalid.value ? "text-red-600" : "text-gray-700", "pl-1"])

function disableValidationError() {
    localErrorMessage.value = ""
    localInvalid.value = false
}

watch(
    () => props.errorMessage,
    (newVal) => {
        localErrorMessage.value = newVal
        localInvalid.value = props.invalid
    }
)

watch(
    () => props.invalid,
    () => {
        localInvalid.value = props.invalid
    }
)
</script>

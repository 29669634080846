import { PayloadIntegration } from "./integration"

export enum PHONE_STATUS {
    ACTIVE = "active",
    REACHABLE = "reachable",
    UNREACHABLE = "unreachable",
    INACTIVE = "inactive",
    UNKNOWN = "unknown",
}

export enum LINE_TYPES {
    LANDLINE = "landline",
    MOBILE = "mobile",
    FIXED_VOIP = "fixedVoip",
    NON_FIXED_VOIP = "nonFixedVoip",
    PERSONAL = "personal",
    TOLL_FREE = "tollFree",
    PREMIUM = "premium",
    SHARED_COST = "sharedCost",
    UAN = "uan",
    VOICEMAIL = "voicemail",
    PAGER = "pager",
    UNKNOWN = "unknown",
}

export interface PhoneNumber {
    phoneNumber: string
    displayName: string
    target?: string
    status?: "invalid" | "valid"
}

export interface PhoneNumberPayload {
    number: string
    country: string
    displayName: string
    target?: string
    integration?: PayloadIntegration
}

<template>
    <AttributeList v-if="isCurrentFilterStep('attributeList')" class="mt-6" @onAttributeSelect="selectAttribute" />
    <FilterRule
        v-if="isCurrentFilterStep('filterRule')"
        class="mt-6"
        :filter="workflowStore.branches[0]?.selectedFilter!"
        :isUpdated="isEditFilter"
        @applyFilter="applyFilter"
        @cancel="cancelFilter"
    />
    <FilterList
        v-if="isCurrentFilterStep('filterList')"
        :savedFiltersGroups="workflowStore.branches[0].filtersGroups"
        :isWorkflow="true"
        @addNewFilter="openAttributeList"
        @editFilter="editFilter"
        @clearFilters="clearFilters"
    />
    <GgmsButton v-if="!workflowStore.branches[0]?.currentStep" class="mt-6" @click="openAttributeList"
        >Add filters <PlusIcon class="w-4 h-4 ml-2"></PlusIcon
    ></GgmsButton>
    <hr class="bg-gray-200 mt-8 mb-4 w-[445px] -mx-6" />
    <p class="text-base leading-6 font-medium text-black py-4">If above criteria are met, go here:</p>
    <label class="text-sm text-gray-700">Branch name</label>
    <draggable
        ref="formChoiceOptions"
        :list="workflowStore.branches"
        item-key="id"
        handle=".handle"
        class="flex flex-col gap-y-2 mb-2 mt-1"
    >
        <template #item="{ element, index }">
            <div
                class="flex items-center justify-center border border-gray-300 shadow-sm h-[42px] rounded-l-md rounded-r-md focus-within:border-primary-color-500 focus-within:ring-1 focus-within:ring-primary-color-500"
            >
                <div class="handle py-2.5 px-3.5 bg-gray-50 rounded-l-md cursor-move">
                    <DragVertical class="h-5 w-5 stroke-gray-400" />
                </div>
                <input
                    v-model="element.value"
                    type="text"
                    :placeholder="`Branch ${index + 1}`"
                    class="w-full border-gray-300 focus:border-primary-color-500 focus:border-l-gray-300 focus:border-r-gray-300 focus:ring-transparent"
                    @keypress.enter.prevent="addNewOption"
                />
                <div class="py-2 px-3.5 bg-gray-50 rounded-r-md cursor-pointer" @click="deleteOption(index)">
                    <TrashIcon class="h-6 w-6 stroke-gray-400" />
                </div>
            </div>
        </template>
    </draggable>
    <GgmsButton
        styleType="simple"
        :icon-start="PlusIcon"
        class="w-fit"
        :classes="['py-0 px-0 pr-2 text-primary-color-600']"
        :icon-classes="['fill-primary-color-600']"
        @click="addNewOption"
        >Add Branch</GgmsButton
    >
    <hr class="bg-gray-200 mt-8 mb-4 w-[445px] -mx-6" />
    <p class="text-base leading-6 font-medium text-black py-4">If none of the criteria are met, go here:</p>
    <GgmsInput
        v-model="workflowStore.noneBranch.value"
        label="‘Not met’ branch name"
        placeholder="Conditions not met"
    ></GgmsInput>
</template>
<script setup lang="ts">
import { ref, nextTick, onMounted, watch } from "vue"
import GgmsButton from "@/components/GgmsButton.vue"
import GgmsInput from "@/components/GgmsInput.vue"
import FilterRule from "@/components/filters/FilterRule.vue"
import FilterList from "@/components/filters/FilterList.vue"
import AttributeList from "@/components/filters/AttributeList.vue"
import { PlusIcon } from "@heroicons/vue/solid"
import { TrashIcon } from "@heroicons/vue/outline"
import DragVertical from "@/components/icons/DragVertical.vue"
import { useGridCollectionStore } from "@/stores/grid-collection"
import { useWorkflowStore } from "@/stores/workflow"
import { Attribute } from "@/shared/models/attributes"
import { Filter } from "@/shared/models/filter"

const workflowStore = useWorkflowStore()
const gridCollectionStore = useGridCollectionStore()

const formChoiceOptions = ref()
const isEditFilter = ref(false)

function addNewOption() {
    workflowStore.branches.push({ value: "" })

    // focus the newly added input
    nextTick(() => {
        focusLastInput()
    })
    if (workflowStore.branches[0].filtersGroups?.length) {
        workflowStore.areButtonDisabled = false
    }
}

function focusLastInput() {
    const inputs = formChoiceOptions.value?.targetDomElement?.querySelectorAll("input")
    if (!inputs?.length) {
        return
    }
    inputs[inputs.length - 1].focus()
}

function deleteOption(index: number) {
    if (workflowStore.branches.length === 1) {
        return
    }
    workflowStore.branches = workflowStore.branches.filter((_, i) => i !== index)
}

function isCurrentFilterStep(step: string) {
    return workflowStore.branches[0]?.currentStep === step
}
function selectAttribute(attribute: Attribute) {
    workflowStore.branches[0].selectedFilter = {
        field: attribute.name,
        type: attribute.type,
        displayName: attribute.displayName,
    }
    workflowStore.branches[0].currentStep = "filterRule"
    isEditFilter.value = false
}

function applyFilter(filter: Filter) {
    if (["body"].includes(filter.field)) {
        filter.field = `messages.${filter.field}`
    }

    isEditFilter.value ? editFilterGroup(filter) : addFilterToGroup(filter)
    openFiltersList()

    workflowStore.areButtonDisabled = false
}

function editFilterGroup(filter: Filter) {
    if (filter.field === "phones") {
        filter.field = "phones.number"
    } else if (filter.field === "emails") {
        filter.field = "emails.emailAddress"
    }
    const branch = workflowStore.branches[0]
    workflowStore.branches.at(0).filtersGroups[branch.selectedFilterGroup].filters[branch.selectedFilterIndex] = filter
}

function addFilterToGroup(filter: Filter) {
    // TODO - we need to discuss about object type
    if (filter.field === "phones") {
        filter.field = "phones.number"
    } else if (filter.field === "emails") {
        filter.field = "emails.emailAddress"
    }
    const branch = workflowStore.branches[0]
    if (!branch) {
        return
    }
    if (!branch?.filtersGroups?.at(branch.selectedFilterGroup!)) {
        workflowStore.branches.at(0)?.filtersGroups?.push({ filters: [filter] })
        return
    }

    workflowStore.branches.at(0)?.filtersGroups?.at(branch.selectedFilterGroup!)?.filters.push(filter)
}

function openAttributeList() {
    workflowStore.branches[0].currentStep = "attributeList"
}

function openFiltersList() {
    workflowStore.branches[0].currentStep = "filterList"
}

function editFilter(filter: Filter, filterIndex: number) {
    workflowStore.branches[0].selectedFilter = {
        field: filter.field,
        type: filter.type,
        rule: filter.rule,
        value: filter.value,
        displayName: filter.displayName,
    }
    isEditFilter.value = true
    workflowStore.branches[0].currentStep = "filterRule"
    workflowStore.branches[0].selectedFilterIndex = filterIndex
}

async function clearFilters() {
    workflowStore.branches[0].currentStep = undefined
    workflowStore.branches[0].selectedFilter = {
        field: "",
        type: "",
        rule: "",
        value: "",
        displayName: "",
    }
    if (!workflowStore.branches.length) {
        workflowStore.areButtonDisabled = true
    }
}

function cancelFilter() {
    resetSelectedFilter()
    openFiltersList()
}

function resetSelectedFilter() {
    workflowStore.branches[0].selectedFilter = {
        field: "",
        type: "",
        rule: "",
        value: "",
    }
}
watch(
    () => workflowStore.noneBranch.value,
    () => (workflowStore.areButtonDisabled = false)
)

watch(
    () => workflowStore.branches,
    () => (workflowStore.areButtonDisabled = false),
    { deep: true }
)
onMounted(() => {
    gridCollectionStore.getPrototypesByPage("contacts")
})
</script>

<template>
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="21" height="21" rx="5" fill="#9CA3AF" />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.5404 3.33687C11.8734 3.44185 12.0999 3.75069 12.0999 4.09986V8.09986L15.2999 8.09986C15.5982 8.09986 15.8717 8.26583 16.0095 8.53041C16.1472 8.795 16.1264 9.11426 15.9553 9.35863L10.3553 17.3586C10.1551 17.6447 9.79242 17.7678 9.45939 17.6629C9.12637 17.5579 8.89991 17.249 8.89991 16.8999V12.8999H5.6999C5.4016 12.8999 5.12808 12.7339 4.99032 12.4693C4.85256 12.2047 4.87346 11.8855 5.04452 11.6411L10.6445 3.64109C10.8448 3.35504 11.2074 3.2319 11.5404 3.33687Z"
            fill="#282B34"
        />
    </svg>
</template>

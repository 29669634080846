<template>
    <div class="flex flex-col">
        <p class="text-lg contacting-7 font-medium text-black pb-6">Date when workflow should trigger</p>
        <div class="flex flex-col gap-y-5">
            <GgmsInput
                v-model="workflowStore.modelValue.date"
                label="Select day"
                type="date"
                :iconStart="CalendarIcon"
            ></GgmsInput>
            <GgmsInput
                v-model="workflowStore.modelValue.time"
                label="Select time"
                type="time"
                :iconStart="ClockIcon"
            ></GgmsInput>
        </div>
    </div>
</template>
<script setup lang="ts">
import { watch } from "vue"
import GgmsInput from "@/components/GgmsInput.vue"
import { CalendarIcon, ClockIcon } from "@heroicons/vue/outline"
import { useWorkflowStore } from "@/stores/workflow"
import { dateTimeConverter } from "@/shared/utils/helpers"

const workflowStore = useWorkflowStore()

watch(
    () => [workflowStore.modelValue?.date, workflowStore.modelValue?.time],
    () => {
        //convert time and date into ISO date
        if (workflowStore.modelValue?.date && workflowStore.modelValue?.time) {
            workflowStore.modelValue.dateTime = dateTimeConverter(
                workflowStore.modelValue.time,
                workflowStore.modelValue.date
            )
        }
        if (workflowStore.modelValue?.date) {
            workflowStore.areButtonDisabled = false
        }
    }
)
</script>

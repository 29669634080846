import axios, { AxiosError, AxiosRequestConfig } from "axios"
import router from "@/router"
import { getToken, getDomainId, getAgencyId, clearStorage } from "@/shared/utils/helpers"
import { ToastService } from "@/shared/services/toast"

const toastService = new ToastService()

export interface GgmsError {
    status: string
    code: string
    field?: string
    message: string
    fullMessage?: string
}

let isToastShown = false

const errorHandler = async (error: unknown | AxiosError) => {
    if (axios.isAxiosError(error)) {
        if (error.response?.status === 401 || error.response?.status === 403) {
            clearStorage()
            if (router.currentRoute.value.name !== "login") {
                await router.push({ name: "login" })
            }

            if (!isToastShown) {
                toastService.addToast({
                    type: "error",
                    message: "Your session expired, please sign in again!",
                })
                isToastShown = true
            }

            return Promise.resolve("Token expired")
        }

        if (error.response?.status === 404) {
            await router.push({ name: "contacts" })
            return
        }

        if (error.response?.data?.error) {
            const data = error.response.data.error

            const indexOfSpace = data.message.indexOf(" ")

            const field = data.message.substring(0, indexOfSpace).toLowerCase()

            let message = data.message.substring(indexOfSpace + 1)
            message = `${message[0].toUpperCase()}${message.slice(1)}`

            if (data.code === "ValidationError") {
                throw {
                    status: data.status,
                    code: data.code,
                    field,
                    message,
                    fullMessage: data.message,
                }
            }

            if (data.code === "TagsInUse") {
                throw data
            }

            toastService.addToast({
                type: "error",
                message: data.message,
            })

            throw data
        }
    } else {
        toastService.addToast({
            type: "error",
            message: "Something went wrong",
        })
    }
}

const client = axios.create({
    baseURL: process.env.VUE_APP_BASE_API_URL,
    timeout: 60000,
})

client.interceptors.request.use((config) => {
    const token = getToken()
    if (
        !token &&
        !["login", "public", "register", "config", "auth"].some((keyword) => (config.url as string).includes(keyword))
    ) {
        clearStorage()
        if (router.currentRoute.value.name !== "login") {
            router.push({ name: "login" })
        }
        return Promise.reject("No auth token")
    }

    const agencyId = getAgencyId()
    config.headers = {
        ...config.headers,
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
        ...(!agencyId ? {} : { "x-crm-agency": agencyId }),
    }

    if (config.url === "/crm/messaging/inbox" && config.method === "get") {
        return config
    }

    const domainId = getDomainId()
    config.headers = {
        ...config.headers,
        ...(!domainId || domainId === "all" ? {} : { "x-crm-domain": domainId }),
    }
    return config
})

client.interceptors.response.use(
    (response) => response.data,
    (error) => errorHandler(error)
)

export class Client {
    get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
        return client.get(url, config)
    }

    post<T = any, D = any>(url: string, data?: D, config?: AxiosRequestConfig): Promise<T> {
        return client.post(url, data, config)
    }

    postFile<T = any, D = any>(url: string, data?: D, config?: AxiosRequestConfig): Promise<T> {
        // Created new client in order to avoid using the Authorization Header
        const newClient = axios.create({
            baseURL: process.env.VUE_APP_BASE_API_URL,
            timeout: 60000,
        })

        return newClient.post(url, data, config)
    }

    patch<T = any, D = any>(url: string, data?: D, config?: AxiosRequestConfig): Promise<T> {
        return client.patch(url, data, config)
    }

    delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
        return client.delete(url, config)
    }

    put<T = any, D = any>(url: string, data?: D, params?: AxiosRequestConfig): Promise<T> {
        return client.put(url, data, params)
    }
}

<template>
    <div>
        <div :class="getWrapperStyleInline()">
            <div class="flex" :class="getRequiredStyleInline">
                <label :for="name" :class="[getLabelStyleInline(), 'block text-sm font-medium text-gray-700 ']">
                    {{ label }}
                </label>
                <span v-if="required" :class="starStyle">*</span>
            </div>

            <p v-if="description" class="text-sm text-gray-500 my-4">
                {{ description }}
            </p>
            <fieldset :class="buttonStyle">
                <!-- If inColumns is true, display options in a grid with preserved gaps -->
                <div
                    v-if="inColumns"
                    class="grid gap-y-4 gap-x-6"
                    :style="{ 'grid-template-columns': `repeat(${getColumnCount()}, 1fr)` }"
                >
                    <div
                        v-for="(option, index) in options"
                        :key="option.value"
                        class="flex items-center cursor-pointer"
                        :class="[disabledStyle, setOptionLengthStyle(option?.isOther)]"
                        @click.prevent.stop="onItemSelect(option.value)"
                    >
                        <template v-if="!option?.isOther">
                            <RadioButton
                                v-model="model"
                                :id="name + '-' + index"
                                :name="name"
                                :value="option.value"
                                :disabled="disabled"
                            />
                            <p
                                :for="name"
                                class="text-sm font-medium text-gray-700 ml-2 cursor-pointer"
                                :class="disabledStyle"
                            >
                                {{ option.displayName }}
                            </p>
                        </template>
                        <div v-else class="flex items-center gap-x-5 w-full">
                            <div class="flex">
                                <RadioButton
                                    v-model="model"
                                    :id="name + '-' + index"
                                    :name="name"
                                    :value="option.value"
                                    :disabled="disabled"
                                />
                                <p
                                    :for="name"
                                    class="text-sm font-medium text-gray-700 ml-2 cursor-pointer"
                                    :class="disabledStyle"
                                >
                                    Other:
                                </p>
                            </div>
                            <GgmsInput
                                v-model="otherValue"
                                :small="true"
                                :disabled="disabled"
                                class="w-full"
                                @click.prevent.stop="notSelectOnInputClick()"
                                @input="onItemSelect('Other')"
                            />
                        </div>
                    </div>
                </div>
                <!-- If inColumns is false, display the original list design with preserved gaps -->
                <div v-else>
                    <div
                        v-for="(option, index) in options"
                        :key="option.value"
                        class="flex items-center cursor-pointer mb-4"
                        :class="[disabledStyle, setOptionLengthStyle(option?.isOther)]"
                        @click.prevent.stop="onItemSelect(option.value)"
                    >
                        <template v-if="!option?.isOther">
                            <RadioButton
                                v-model="model"
                                :id="name + '-' + index"
                                :name="name"
                                :value="option.value"
                                :disabled="disabled"
                            />
                            <p
                                :for="name"
                                class="text-sm font-medium text-gray-700 ml-2 cursor-pointer"
                                :class="disabledStyle"
                            >
                                {{ option.displayName }}
                            </p>
                        </template>
                        <div v-else class="flex items-center gap-x-5 w-full">
                            <div class="flex">
                                <RadioButton
                                    v-model="model"
                                    :id="name + '-' + index"
                                    :name="name"
                                    :value="option.value"
                                    :disabled="disabled"
                                />
                                <p
                                    :for="name"
                                    class="text-sm font-medium text-gray-700 ml-2 cursor-pointer"
                                    :class="disabledStyle"
                                >
                                    Other:
                                </p>
                            </div>
                            <GgmsInput
                                v-model="otherValue"
                                :small="true"
                                :disabled="disabled"
                                class="w-full"
                                @click.prevent.stop="notSelectOnInputClick()"
                                @input="onItemSelect('Other')"
                            />
                        </div>
                    </div>
                </div>
            </fieldset>
            <p v-if="localErrorMessage" class="mt-2 text-sm text-red-600">{{ localErrorMessage }}</p>
        </div>
    </div>
</template>

<script setup lang="ts">
import GgmsInput from "@/components/GgmsInput.vue"
import { withDefaults, computed, ref, watch } from "vue"

interface OptionType {
    displayName: string
    value: any
    isOther?: boolean
    otherValue?: string
}

interface Props {
    modelValue: any
    label?: string
    description?: string
    required?: boolean
    inline?: boolean
    disabled?: boolean
    options: OptionType[]
    name?: string
    invalid?: boolean
    buttonsInline?: boolean
    errorMessage?: string
    inColumns?: boolean // New prop to determine if options should be displayed in columns
}

const props = withDefaults(defineProps<Props>(), {
    modelValue: "",
    label: "",
    required: false,
    disabled: false,
    options: [] as OptionType[],
    inline: false,
    name: "",
    invalid: false,
    buttonsInline: false,
    inColumns: false, // Default value for inColumns
})
const emit = defineEmits(["update:modelValue", "update:options"])

const model = computed({
    get() {
        return props.modelValue
    },
    set(value: string) {
        emit("update:modelValue", value)
    },
})

const disabledStyle = computed(() => (props.disabled ? "cursor-not-allowed opacity-70" : ""))
const starStyle = computed(() => [localInvalid.value ? "text-red-600" : "text-gray-700", "pl-1"])
const localInvalid = ref(props.invalid)
const localErrorMessage = ref(props.errorMessage)
const getRequiredStyleInline = computed(() => (props.inline ? "gap-2" : ""))
const otherValue = ref(props.options.at(-1)?.otherValue || "")
const buttonStyle = computed(() => [props.buttonsInline ? "gap-x-4" : "flex-col gap-y-4", "flex"])

watch(
    () => props.invalid,
    () => (localInvalid.value = props.invalid)
)

watch(
    () => props.errorMessage,
    () => {
        localErrorMessage.value = props.errorMessage
        localInvalid.value = props.invalid
    }
)

function disableValidationError() {
    localErrorMessage.value = ""
    localInvalid.value = false
}

function getWrapperStyleInline() {
    return props.inline ? "flex items-center gap-4" : ""
}

function getLabelStyleInline() {
    return props.inline ? "w-2/5 whitespace-nowrap pr-2 lg:pr-0" : "mb-4"
}

function setOptionLengthStyle(isOther: boolean | undefined) {
    return isOther ? "w-full" : "w-fit"
}

function onItemSelect(value: any) {
    if (props.disabled) return
    disableValidationError()
    model.value = value
    emit("update:options", otherValue.value)
}

// Function to calculate the number of columns for grid display
function getColumnCount() {
    if (!props.inColumns) return 1
    return Math.ceil(Math.sqrt(props.options.length)) // Square root approximation for equal columns
}

// Prevents the radio button from being selected when clicking on the input field
function notSelectOnInputClick() {
    return
}
</script>
